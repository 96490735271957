<template>
<div>
  <div style="width: 800px;height: 700px;float: left;">
    <div id="echartPower" style="height: 680px;width: 800px;float: right;"></div>
  </div>
  <div style="width: 800px;height: 700px;float: right;">
    <div id="echartACVoltage" style="height: 680px;width: 800px;float: right;"></div>
  </div>
</div>
</template>

<script>
import * as echarts from 'echarts'
import { postGetInverterACVoltage, postGetInverterOperation } from '@/api/api'

export default {
  name: 'photovoltaicOperation',
  data () {
    return {
      date: this.$store.state.chooseDate,
      time: '',
      listPower: {},
      listACVoltage: {}
    }
  },
  mounted () {
    this.getInverterPower()
    this.getInverterACVoltage()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      // return this.$store.state.chooseDate
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
      // eslint-disable-next-line no-unreachable
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      // console.log('this.date',this.date)
      this.getInverterPower()
      this.getInverterACVoltage()
    }
  },
  methods: {
    // 功率曲线
    getInverterPower () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postGetInverterOperation({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        data: this.date
      }).then(
        res => {
          // console.log('value', res)
          this.listPower = res.statisticsTable
          // console.log(this.listPower)
          this.getechartPower()
        }
      )
    },
    // 交流电压统计曲线
    getInverterACVoltage () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postGetInverterACVoltage({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        data: this.date
      }).then(
        res => {
          // console.log('value', res)
          this.listACVoltage = res.statisticsTable
          // console.log(this.listPower)
          this.getechartACVoltage()
        }
      )
    },
    getechartPower () {
      let dataList = []
      let labels = []
      dataList = this.listPower.dataList
      labels = this.listPower.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartPower')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '设备功率曲线', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000', // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: '设备功率曲线',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getechartACVoltage () {
      let dataList = []
      let labels = []
      dataList = this.listACVoltage.dataList
      labels = this.listACVoltage.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartACVoltage')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '设备交流电压统计', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000', // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: '设备交流电压统计',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>

</style>
