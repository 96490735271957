<template>
  <div class="main" style="width: 100%;height: 100%">
    <div class="top">
      <div class="chooseYear" style="padding-top: 15px;line-height: 0;text-align: left;padding-left: 10px" >
        <el-select v-model="value" placeholder="请选择年份" @change="getYear()">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="center" style="height: 270px;margin-top: 15px;width: 100%">
        <div class="left" style="width: 44%;float: left">
          <el-table
            :data="tableData" style="height: 290px;"
            max-height="300px" @row-click="clickDate">
            <el-table-column
              prop="time"
              align="center"
              label="年-月"
              width="145px">
            </el-table-column>
            <el-table-column
              prop="data_p_u_a"
              align="center"
              label="A相"
              width="185px">
            </el-table-column>
            <el-table-column
              prop="data_p_u_b"
              align="center"
              label="B相"
              width="185px">
            </el-table-column>
            <el-table-column
              prop="data_p_u_c"
              align="center"
              label="C相"
              width="185px">
            </el-table-column>
          </el-table></div>
        <div class="right" style="float: right;width: 56%;">
          <el-table
            :data="dayData" style="height: 290px;"
            max-height="300px">
            <el-table-column
              prop="timestamp"
              align="center"
              label="日"
              width="160px">
            </el-table-column>
            <el-table-column
              prop="pf_a"
              align="center"
              label="A相"
              width="240px">
            </el-table-column>
            <el-table-column
              prop="pf_b"
              align="center"
              label="B相"
              width="240px">
            </el-table-column>
            <el-table-column
              prop="pf_c"
              align="center"
              label="C相"
              width="240px">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="bottom" style="height:405px;margin-top: 60px">
      <div id="echartI" style="height: 405px;width: 1620px;float: left"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { postPowerFactorDayValue } from '@/api/api'

export default {
  name: 'powerFactor',
  data () {
    return {
      date: this.$store.state.chooseDate,
      time: '',
      tableData: [],
      dayData: [],
      pf_arr: {},
      options: [{
        value: '2024',
        label: '2024'
      }, {
        value: '2023',
        label: '2023'
      }],
      value: '',
      month: ''
    }
  },
  mounted () {
    this.getCurrentDate()
    this.getValue()
    this.getDayValue()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.state.chooseDate
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      this.getValue()
    }
  },
  methods: {
    /* 下拉框选中年份 */
    getYear () {
      // console.log('year', this.value)
      this.date = this.value
      this.getValue()
    },
    /* 点击左边月份右边更新 */
    clickDate (row) {
      // console.log('a', row)
      this.month = row.time
      this.getDayValue()
    },
    getValue () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postPowerFactorValue({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.date
      }).then(
        res => {
          // console.log('yearvalue', res)
          let list = []
          list = res.statisticsList.pf_list
          // console.log('list', list)
          this.tableData = list
          this.pf_arr = res.statisticsTable
          // console.log('this.rms_i_arr', this.rms_i_arr)
          this.getEchartI()
        }
      )
    },
    getDayValue () {
      this.$apiFun.postPowerFactorDayValue({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        month: this.month
      }).then(
        res => {
          // console.log('yearvalue', res)
          let list = []
          list = res.statisticsList.pf_list
          // console.log('list', list)
          this.dayData = list
          this.pf_arr = res.statisticsTable
          this.getEchartI()
        }
      )
    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      this.date = year + '-' + month + '-' + day
      this.month = year + '-' + month
      // console.log('time', this.time)
    },
    getEchartI () {
      let dataList = []
      let labels = []
      dataList = this.pf_arr.dataList
      labels = this.pf_arr.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartI')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '功率因素 ', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000', // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相',
            data: dataList[0],
            type: 'line',
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相',
            data: dataList[1],
            type: 'line',
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相',
            data: dataList[2],
            type: 'line',
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .has-gutter {
  line-height: 10px;
}

::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 20px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 20px;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}
</style>
