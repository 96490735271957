import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from "echarts";
import '@/utils/rem'
// 公共样式
import '@/assets/styles/index.scss'
// 导入全局样式表
import '@/assets/css/global.scss'
import * as apiFun from './api/api'
Vue.prototype.$apiFun = apiFun// 请求接口api

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
  // beforeCreate () {
  //   Vue.prototype.$apiFun = apiFun
  // }
}).$mount('#app')
