<template>
  <div class="main" style="width: 100%; height: 100%">
    <div class="butt" style="width: 80%; height: 100%">
      <div class="item" style="width: 190px;margin-top: -2px">
        <el-cascader
          filterable
          :show-all-levels="false"
          v-model="value"
          :options="options"
          :placeholder="placeholder"
          :props="{ expandTrigger: 'hover' }"
          @input.native="inputChange"
          @change="handleChange"></el-cascader>
      </div>
      <div class="item">
        <router-link to="/information">
          <el-button type="primary" plain>详情</el-button>
        </router-link>
      </div>
      <div class="item" v-show='this.$store.state.dev_category==4? false:true'>
        <router-link to="/electric">
          <el-button type="primary" plain>电能质量</el-button>
        </router-link>
      </div>
      <div class="item">
        <router-link to="/inverter" v-show='this.$store.state.dev_category==4? true:false'>
          <el-button type="primary" plain>统计</el-button>
        </router-link>
      </div>
      <div class="item">
        <router-link to="/liveData">
          <el-button type="primary" plain>实时</el-button>
        </router-link>
      </div>
      <div class="item">
        <router-link to="/history">
          <el-button type="primary" plain>历史</el-button>
        </router-link>
      </div>
      <div class="item">
        <router-link to="/warn">
          <el-button type="primary" plain>告警</el-button>
        </router-link>
      </div>

      <div class="item">
        <router-link to="/maintenance">
          <el-button type="primary" plain>维保</el-button>
        </router-link>
      </div>
      <!--     <div style="width: 85%;margin-left: 10px;height: 100%; float: left;line-height: 100%" >-->
      <!--       <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"  background-color= 'transparent' text-color="#fff" active-text-color="#045fbd" @select="handleSelect">-->
      <!--         <router-link to="/information">-->
      <!--           <el-menu-item index="1" style="width: 25%">详情</el-menu-item>-->
      <!--         </router-link>-->
      <!--         <router-link to="/electric">-->
      <!--           <el-menu-item index="2" style="width: 25%">电能质量</el-menu-item>-->
      <!--         </router-link>-->
      <!--         <router-link to="/history">-->
      <!--           <el-menu-item index="3" style="width: 25%">历史</el-menu-item>-->
      <!--         </router-link>-->
      <!--         <router-link to="/maintenance">-->
      <!--           <el-menu-item index="4" style="width: 25%">维保</el-menu-item>-->
      <!--         </router-link>-->
      <!--       </el-menu>-->
      <!--     </div>-->
    </div>
    <div style="position: absolute; top: 0;right: 0;display: flex;margin-top: -3px">
      <el-date-picker
        style="width: 190px;height: 80px;line-height: 80px;margin-right: 10px"
        v-model="value1"
        type="date"
        @change="changeDate()"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        placeholder="选择日期">
      </el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headNav',
  data () {
    return {
      isAbc: 0,
      determine: this.$store.state.confirmIndex,
      activeIndex: '1',
      activeIndex2: '1',
      value: [],
      options: [],
      date: {
        createDate: new Date()
      },
      value1: '',
      placeholder: '请选择设备'
    }
  },
  mounted () {
    this.getEquipmentList()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    options: {
      handler (newVal) {
        ++this.isAbc
      }
    },
    status () {
      this.getEquipmentList()
    }
  },
  methods: {
    inputChange (el) {
      //el.target.value就是实时的用户输入，
      // console.log('el',el)
      // 建议加个判断
      // const val = el.target.value;
      // if (val) {
      //   // 这里放入请求api操作
      // } else {
      //   // 这里是用户情况数据的时候，可以把初始的数据重置放这里
      // }
    },
    handleChange (value) {
      // console.log('value.slice(-1)[0]', value)
      if (value.length === 1) {
        this.$store.commit('setDevName', value[0].dev_name)
        this.placeholder = value[0].dev_name
        this.$store.commit('setDeviceId', value[0].dev_id)
        const status = this.$store.state.chooseDevice// 重新赋值
        this.$store.commit('changeDevice', !status)
        // console.log('value[0].dev_name',value[0].dev_name)

        this.$store.commit('setConfirmIndex', value[0].en_index)
        this.$store.commit('setDev_category', value[0].dev_category)
        this.$store.commit('setDev_type', value[0].dev_type)
        this.$store.commit('setDev_p_type', value[0].dev_p_type)
        this.$store.commit('setMt_type', value[0].mt_type)
        // console.log('confirm', this.$store.state.mt_type)
      } else {
        this.$store.commit('setDevName', value.slice(-1)[0].dev_name)
        this.placeholder = value.slice(-1)[0].dev_name
        this.$store.commit('setDeviceId', value.slice(-1)[0].dev_id)
        const status = this.$store.state.chooseDevice// 重新赋值
        this.$store.commit('changeDevice', !status)
        // console.log('valueindex', value.slice(-1)[0].en_index)
        this.$store.commit('setConfirmIndex', value.slice(-1)[0].en_index)
        this.$store.commit('setDev_category', value.slice(-1)[0].dev_category)
        this.$store.commit('setDev_type', value.slice(-1)[0].dev_type)
        this.$store.commit('setDev_p_type', value.slice(-1)[0].dev_p_type)
        this.$store.commit('setMt_type', value.slice(-1)[0].mt_type)
        // console.log('confirm', this.$store.alert.confirmIndex)
      }
      // self.$refs.value.options = []
      this.$router.push('/information')
    },
    getEquipmentList () {
      this.options = []
      // console.log('wins', window.localStorage.userId, window.localStorage.token, window.localStorage.customerCode)
      this.$apiFun.postEquipmentList(
        {
          userId: window.localStorage.userId,
          token: window.localStorage.token,
          code: window.localStorage.customerCode
        }
      ).then(
        res => {
          // console.log('ress', res.dataInfo.list)
          const list = res.dataInfo.list
          const arr1 = []
          list.forEach(function (item, index) {
            // console.log('item', item.mt_type)
            arr1.push({
              label: item.dev_name,
              value: {
                dev_id: item.dev_id,
                dev_name: item.dev_name,
                en_index: item.en_index,
                dev_category: item.dev_category,
                dev_type: item.dev_type,
                dev_p_type: item.dev_p_type,
                mt_type: item.mt_type
              },
              dev_id: item.dev_id
            })
            if (item.children) {
              const arr2 = []
              item.children.forEach(function (item1, index1) {
                arr2.push({
                  label: item1.dev_name,
                  value: {
                    dev_id: item1.dev_id,
                    dev_name: item1.dev_name,
                    en_index: item1.en_index,
                    dev_category: item1.dev_category,
                    dev_type: item1.dev_type,
                    dev_p_type: item1.dev_p_type,
                    mt_type: item1.mt_type
                  },
                  dev_id: item1.dev_id
                })
                // sanji
                if (item1.children) {
                  const arr3 = []
                  item1.children.forEach(function (item2) {
                    arr3.push({
                      label: item2.dev_name,
                      value: {
                        dev_id: item2.dev_id,
                        dev_name: item2.dev_name,
                        en_index: item2.en_index,
                        dev_category: item2.dev_category,
                        dev_type: item2.dev_type,
                        dev_p_type: item2.dev_p_type,
                        mt_type: item2.mt_type
                      },
                      dev_id: item2.dev_id
                    })
                  })
                  arr2[index1].children = arr3
                }
              })
              arr1[index].children = arr2
            }
          })

          this.options = arr1
          // console.log('options', this.options)
        }
      )
    },
    changeDate () {
      // console.log(this.value1)
      const status = this.$store.state.chooseDevice// 重新赋值
      this.$store.commit('changeDevice', !status)
      this.$store.commit('setChooseDate', this.value1)
    }
  }
}
</script>

<style scoped lang="scss">

.main {
  .butt {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    ::v-deep .el-menu-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    ::v-deep .el-button--primary {
      background-color: transparent;
      width: 180px;
      text-align: center;
      font-family: SimHei, serif;
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: rgba(66, 120, 213, 0.3);
      border-color: rgba(66, 120, 213, 0.3);
      color: #fff;
    }
  }

}
</style>
