<template>
<div >
  <div style="width: 1800px;height: 700px;">
    <div style="height: 70px;width: 1800px;margin-bottom: 30px">
      <div style="width: 200px;float: left;line-height: 0;margin-top: 10px">
        <el-button-group>
          <el-button  type="primary" @click="handleClick($event)">日</el-button>
          <el-button type="primary" @click="handleClick($event)">月</el-button>
          <el-button type="primary" @click="handleClick($event)">年</el-button>
        </el-button-group>
      </div>
    </div>
    <div id="echartStatistics" style="height: 600px;width: 1600px;"></div>
  </div>
</div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'photovoltaicStatistics',
  data () {
    return {
      date: this.$store.state.chooseDate,
      time: 0,
      listStatistics: {},
      type: ''
    }
  },
  mounted () {
    this.getInverterStatistics()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      // return this.$store.state.chooseDate
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
      // eslint-disable-next-line no-unreachable
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      // console.log('this.date',this.date)
      this.getInverterStatistics()
    }
  },
  methods: {
    handleClick (event) {
      let val = event.currentTarget.innerHTML.match(/(<span>=?)(\S*)(?=<\/span>)/)[2]
      // console.log(val)
      if (val === '日') {
        this.time = 0
      } else if (val === '月') {
        this.time = 1
      } else {
        this.time = 2
      }
      // console.log('time', this.time)
      this.getInverterStatistics()
    },
    // 日月年
    getInverterStatistics () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postGetInverterOperation({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        type: this.time,
        data: this.date
      }).then(
        res => {
          // console.log('value', res)
          this.listStatistics = res.statisticsTable
          // console.log(this.listPower)
          this.getechartStatistics()
        }
      )
    },
    getechartStatistics () {
      let dataList = []
      let labels = []
      dataList = this.listStatistics.dataList
      labels = this.listStatistics.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartStatistics')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '发电量统计', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000' // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: '月发电量统计',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
  }
}
</script>

<style scoped>

</style>
