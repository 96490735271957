<template>
<div style="width: 100%;height: 100%;">
  <div class="content" style="width: 100%;height: 100%;">
    <div style="height: 800px;width: 100%">
<!--      <div style="width: 100%;height: 40px;line-height: 0;padding-top: 20px;padding-left: 20px"  v-show= 'this.$store.state.dev_type == 1? true:false '>-->
<!--        <el-select style="display: block; width: 30%;" v-model="value" placeholder="请选择逆变器" @change="handleChange">-->
<!--          <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.dev_id"-->
<!--            :label="item.dev_name"-->
<!--            :value="item.dev_id"-->
<!--          >-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </div>-->
      <div style="width: 100%;height: 720px;padding-top: 40px">
        <router-view></router-view>
      </div>
    </div>
    <div class="bottom"  style="width: 30%;padding: 0;margin-left: 30%;height: 20px">
      <div class="item" >
        <router-link to="/photovoltaicStatistics">
          <el-button type="primary" plain>数据统计</el-button>
        </router-link>
      </div>
      <div class="item"   v-show= 'this.$store.state.dev_type == 0? true:false '>
      <router-link to="/photovoltaicMonitor">
        <el-button type="primary" plain>光伏监测</el-button>
      </router-link>
    </div>
      <div class="item">
        <router-link to="/photovoltaicOperation">
          <el-button type="primary" plain>运行概括</el-button>
        </router-link>
      </div>
      <div class="item"  v-show= 'this.$store.state.dev_type == 0? true:false '>
        <router-link to="/#">
          <el-button type="primary" plain>气象展示</el-button>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { postGetInverterSubList } from '@/api/api'

export default {
  name: 'inverter',
  data () {
    return {
      options: [],
      value: '',
      date: this.$store.state.chooseDate,
      dev_id: ''
    }
  },
  mounted () {
    this.getChooseList()
    // document.getElementById('device').style.display = 'none'
    // document.getElementById('device1').style.display = 'none'
  },
  //  A 页面（组件）
  computed: {
    status () {
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      this.getChooseList()
    }
  },
  methods: {
    handleChange (value) {
      // console.log('value', value)
      let message = value
      let message_split = message.split(',')
      console.log(message_split)
      this.dev_id = message_split[0]
      this.$store.commit('setDev_type',message_split[1] )
      this.$store.commit('setDeviceId', this.dev_id)
      const status = this.$store.state.chooseDevice// 重新赋值
      this.$store.commit('changeDevice', !status)
      // if (value.length === 1) {
      //   this.$store.commit('setDev_type', value[0].dev_type)
      //   console.log('confirm', value[0].dev_type)
      // } else {
      //   this.$store.commit('setDev_type', value.slice(-1)[0].dev_type)
      //   // console.log('confirm', this.$store.alert.confirmIndex)
      // }
    },
    getChooseList () {
      this.$apiFun.postGetInverterSubList({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId
      }).then(
        res => {
          // console.log('list', res)
          this.options = res.inSubList
          // console.log('list', this.options)
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 0;
  ::v-deep .el-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  ::v-deep .el-button--primary {
    background-color: transparent;
    width: 140px;
    height: 40px;
    font-family: SimHei, serif;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }

  ::v-deep .el-button--primary:focus,
  .el-button--primary:hover {
    background: rgba(66, 120, 213, 0.3);
    border-color: rgba(66, 120, 213, 0.3);
    color: #fff;
  }

}
//选择框样式
::v-deep .el-radio-group {
  float: left;
}
</style>
