<template>
<div>
  <div class="main" style="width: 100%;height: 100%">
    <div class="top">
      <div style="line-height: 30px;height: 30px;width: 900px;float: left;margin-top: 10px;margin-bottom: 20px">
        <el-select style="display: block; width: 100%;" v-model="value" placeholder="请选择谐波率" @change="handleChange">
          <el-option
            v-for="item in options"
            :key="item.timestamp"
            :label="item.de_name"
            :value="item.timestamp"
            @change="handleChange">
          </el-option>
        </el-select>
      </div>
      <el-table
        :data="tableData" style="height: 276px"
        max-height="276" :show-header="false">
        <el-table-column
          prop="num"
          align="center"
          width="210px">
        </el-table-column>
        <el-table-column
          prop="fft_ua"
          align="center"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="fft_ub"
          align="center"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="fft_uc"
          align="center"
          width="260px">
        </el-table-column>
        <el-table-column
          prop="fft_ia"
          align="center"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="fft_ib"
          align="center"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="fft_ic"
          align="center"
          width="230px">
        </el-table-column>

      </el-table>
    </div>
    <div class="bottom" style="height:460px;margin-top: 10px">
      <div id="echartU" style="height: 460px;width: 800px;float: left"></div>
      <div id="echartI" style="height: 460px;width: 800px;float: right"></div>
    </div>
  </div>
</div>
</template>

<script>
import * as echarts from 'echarts'
import { postHarmonicWaveValue } from '@/api/api'

export default {
  name: 'harmonicWaveValue',
  data () {
    return {
      options: [],
      value: '',
      date: this.$store.state.chooseDate,
      time: '',
      tableData: [],
      thd_u_arr: {},
      rms_u_arr: {}
    }
  },
  mounted () {
    this.getCurrentDate()
    this.getChooseList()
    this.getValue()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.state.chooseDate
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      this.getChooseList()
      this.getValue()
    }
  },
  methods: {
    handleChange (value) {
      // console.log('value', value)
      this.time = value
      this.getValue()
    },
    getChooseList () {
      this.$apiFun.postHarmonicWaveValueList({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.date
      }).then(
        res => {
          // console.log('list', res)
          this.options = res.statisticsList
          // console.log('list', this.options)
        }
      )
    },
    getValue () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postHarmonicWaveValue({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        timestamp: this.time
      }).then(
        res => {
          // console.log('value', res)
          let list = []
          list = res.statisticsList.fft_list
          // console.log('list', list)
          this.tableData = list
          this.thd_u_arr = res.statisticsTable.fft_U_table
          this.thd_i_arr = res.statisticsTable.fft_I_table
          // console.log('this.rms_i_arr', this.rms_i_arr)
          this.getEchartI()
          this.getEchartsU()
        }
      )
    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      // this.date = year + '-' + month + '-' + day
      // this.time = new Date().getTime()
      // console.log('time', this.time)
    },
    getEchartI () {
      let dataList = []
      let labels = []
      dataList = this.thd_i_arr.dataList
      labels = this.thd_i_arr.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartI')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '电流畸变含量 ', // 主标题名称
          x: '40',
          y: '8',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000', // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电流畸变含量',
            data: dataList[0],
            type: 'bar'
          },
          {
            name: 'B相电流畸变含量',
            data: dataList[1],
            type: 'bar'
          },
          {
            name: 'C相电流畸变含量',
            data: dataList[2],
            type: 'bar'
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartsU () {
      let dataList = []
      let labels = []
      dataList = this.thd_u_arr.dataList
      labels = this.thd_u_arr.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartU')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '电压畸变含量 ', // 主标题名称
          x: '40',
          y:'8',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000' // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电压畸变含量',
            data: dataList[0],
            type: 'bar'
          },
          {
            name: 'B相电压畸变含量',
            data: dataList[1],
            type: 'bar'
          },
          {
            name: 'C相电压畸变含量',
            data: dataList[2],
            type: 'bar'
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
::v-deep .has-gutter {
  line-height: 10px;
}
::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

/*::v-deep .el-table th {*/
/*  color: #ffffff; !* 字体颜色 *!*/
/*  font-size: 20px;*/
/*  font-weight: 400;*/
/*  background-color: #033c76 !important; !* 背景透明 *!*/
/*  border: 0;*/
/*}*/

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 20px;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}
</style>
