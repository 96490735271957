<template>
  <div class="main" style="width: 100%; height: 100%; ">
    <el-container style="width: 100%; height: 100%;">
      <el-aside width="290px">
        <aside-list></aside-list>
      </el-aside>
      <el-container>
        <el-header height="70px">
          <head-nav></head-nav>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import headNav from '@/views/equipment/headNav'
import asideList from '@/views/equipment/aside'
import mqtt from 'mqtt'
import { v4 } from 'uuid'

export default {
  name: 'equipment',
  data () {
    return {
      logs: [],
      msg: 0,
      uuid: 8888
    }
  },
  components: {
    headNav,
    asideList
  },
  mounted () {
    const msg1 = this.msg
    this.uuid = v4()
    // console.log('this.uuid', this.uuid)
    this.initMqtt()
    this.mqttReceive()
  },
  destroyed () {
    if (this.client.end) this.client.end()
  },
  methods: {
    /**
     * @name:初始化mqtt
     * @msg:
     * @param {*}
     * @return {*}
     */
    initMqtt () {
      const vm = this
      // const commonApi = 'http://113.141.171.81:1883'
      const mqtt = require('mqtt')
      // console.log('this.uuid', this.uuid)
      const options = {
        // mqtt客户端的id
        clientId: this.uuid,
        username: 'admin',
        password: '123456',
        // useSSL: true,
        rejectUnauthorized: false,
        clean: true, // 保留会话
        cleanSession: true,
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        keepalive: 60
      }
      vm.client = mqtt.connect('wss://api.jsttpower.cn:9002', options)
      // vm.client = mqtt.connect('ws://127.0.0.1:9001', options)
      this.client.on('connect', function () {
        console.log('equipment连接成功....')
      })
      // 如果连接错误，打印错误
      vm.client.on('error', function (err) {
        console.log('err=>', err)
        vm.client.end()
      })
      // 重连回调
      vm.client.on('reconnect', (error) => {
        console.log('正在重连MQTT服务器:', error)
      })
    },
    /**
     * @name:发布mqtt消息
     * @msg:
     * @param {*}
     * @return {*}
     */
    mqttPublish () {
      const topic = 'topic_ttwlMsg' + '/' + window.localStorage.code+ '_WEB'
      this.client.publish(topic, JSON.stringify(this.message))
    },
    /**
     * @name:接收mqtt消息
     * @msg:
     * @param {*}
     * @return {*}
     */
    mqttReceive () {
      // console.log('topicrecive', window.localStorage.code)
      const topic = 'topic_ttwlMsg' + '/' + window.localStorage.code+ '_WEB'// 和后台约定好的主题
      // console.log('topic', topic)
      const vm = this
      vm.client.subscribe(topic, function (err) {
        if (!err) {
          console.log('订阅成功')
        } else {
          // 打印错误
          console.log('err', err)
        }
      })
      vm.client.on('message', function (topic, message) {
        // console.log(message.toString())
        vm.logs.push(message)
        const status = vm.$store.state.chooseDevice// 重新赋值
        vm.$store.commit('changeDevice', !status)
        // console.log('vm.$store.state.chooseDevice', vm.$store.state.chooseDevice)
      })
    },
    canParseToJson (str, parseFlag = true) {
      try {
        if (typeof JSON.parse(str) === 'object' && Object.prototype.toString.call(JSON.parse(str)) === '[object Object]') {
          return parseFlag === true ? JSON.parse(str) : true
        }
      } catch (e) {
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
.el-header {
  margin-left: 6px;
  border: 1px solid rgba(7, 118, 181, .7);
  box-shadow: inset 0 0 5px rgba(7, 118, 181, .8);
  position: relative;
  color: #fff;
  text-align: center;
  height: 60px;
  line-height: 70px;
}

.el-aside {
  margin-left: 2px;
  border: 1px solid rgba(7, 118, 181, .7);
  box-shadow: inset 0 0 5px rgba(7, 118, 181, .8);
  position: relative;
  color: #fff;
  text-align: center;
}

.el-main {
  //background-color: #338FCC;
  text-align: center;
  //opacity: 0.5;
  line-height: 160px;
  padding: 0;
  margin-left: 6px;
}

body > .el-container {
  margin-bottom: 10px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
</style>
