<template>
  <div class="mian" style="height: 805px">
    <div style="height:805px" v-show= 'this.$store.state.dev_category == 4? false:true '>
      <el-table
        :data="tableData" style="height:810px"
        max-height="787">
        <el-table-column
          prop="timestamp"
          align="center"
          label="时间"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_ua"
          align="center"
          label="A相电压"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_ub"
          align="center"
          label="B相电压"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_uc"
          align="center"
          label="C相电压"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_ia"
          align="center"
          label="A相电流"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_ib"
          align="center"
          label="B相电流"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_ic"
          align="center"
          label="C相电流"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="p_t"
          align="center"
          label="总有功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="p_a"
          align="center"
          label="A相有功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="p_b"
          align="center"
          label="B相有功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="p_c"
          align="center"
          label="C相有功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="q_t"
          align="center"
          label="总无功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="q_a"
          align="center"
          label="A相无功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="q_b"
          align="center"
          label="B相无功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="q_c"
          align="center"
          label="C相无功功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="s_t"
          align="center"
          label="总视在功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="s_a"
          align="center"
          label="A相视在功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="s_b"
          align="center"
          label="B相视在功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="s_c"
          align="center"
          label="C相视在功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="pf_t"
          align="center"
          label="总功率因素"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="pf_a"
          align="center"
          label="A相功率因素"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="pf_b"
          align="center"
          label="B相功率因素"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="pf_c"
          align="center"
          label="C相功率因素"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_va"
          align="center"
          label="A相线电压"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_vb"
          align="center"
          label="B相线电压"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="rms_vc"
          align="center"
          label="C相线电压"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="f_a"
          align="center"
          label="A相频率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="f_b"
          align="center"
          label="B相频率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="f_c"
          align="center"
          label="C相频率"
          width="200px">
        </el-table-column>
        <el-table-column
          align="center"
          label="总"
          width="200px">
          <el-table-column
            prop="wp_plus"
            align="center"
            label="正向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wp_minus"
            align="center"
            label="反向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_plus"
            align="center"
            label="正向无功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_minus"
            align="center"
            label="反向无功电能"
            width="200px">
          </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          label="峰"
          width="200px">
          <el-table-column
            prop="wp_plus1"
            align="center"
            label="正向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wp_minus1"
            align="center"
            label="反向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_plus1"
            align="center"
            label="正向无功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_minus1"
            align="center"
            label="反向无功电能"
            width="200px">
          </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          label="尖"
          width="200px">
          <el-table-column
            prop="wp_plus2"
            align="center"
            label="正向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wp_minus2"
            align="center"
            label="反向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_plus2"
            align="center"
            label="正向无功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_minus2"
            align="center"
            label="反向无功电能"
            width="200px">
          </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          label="谷"
          width="200px">
          <el-table-column
            prop="wp_plus3"
            align="center"
            label="正向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wp_minus3"
            align="center"
            label="反向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_plus3"
            align="center"
            label="正向无功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_minus3"
            align="center"
            label="反向无功电能"
            width="200px">
          </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          label="平"
          width="200px">
          <el-table-column
            prop="wp_plus4"
            align="center"
            label="正向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wp_minus4"
            align="center"
            label="反向有功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_plus4"
            align="center"
            label="正向无功电能"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="wq_minus4"
            align="center"
            label="反向无功电能"
            width="200px">
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="u_rate"
          align="center"
          label="电网电压不平衡度"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="i_rate"
          align="center"
          label="电流电流不平衡度"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="p_require"
          align="center"
          label="有功最大需量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="q_require"
          align="center"
          label="无功最大需暈"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="u_require"
          align="center"
          label="电压最大需量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="i_require"
          align="center"
          label="电流最大需量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="thd_ua"
          align="center"
          label="电网A相电压总谐波含量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="thd_ub"
          align="center"
          label="电网B相电压总谐波含量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="thd_uc"
          align="center"
          label="电网C相电压总谐波含量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="thd_ia"
          align="center"
          label="电网A相电流总谐波含量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="thd_ib"
          align="center"
          label="电网B相电流总谐波含量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="thd_ic"
          align="center"
          label="电网C相电流总谐波含量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="fft_ua"
          align="center"
          label="电网A相电压谐波"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="fft_ub"
          align="center"
          label="电网B相电压谐波"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="fft_uc"
          align="center"
          label="电网C相电压谐波"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="fft_ia"
          align="center"
          label="电网A相电流谐波"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="fft_ib"
          align="center"
          label="电网B相电流谐波"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="fft_ic"
          align="center"
          label="电网C相电流谐波"
          width="200px">
        </el-table-column>
      </el-table>
    </div>
<!--    逆变器历史 -->
    <div style="height:805px" v-show= 'this.$store.state.dev_category == 4? true:false '>
      <el-table
        :data="tableData" style="height:810px"
        max-height="787">
        <el-table-column
          prop="timestamp"
          align="center"
          label="上报时间"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="dev_name"
          align="center"
          label="设备名称"
          width="400px">
        </el-table-column>
        <el-table-column
          prop="creationDate"
          align="center"
          label="数据最新时间"
          width="300px">
        </el-table-column>
        <el-table-column
          prop="out_pac"
          align="center"
          label="发电功率(千瓦）"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="capacity"
          align="center"
          label="容量 kw"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="tempperature"
          align="center"
          label="温度"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="eDay"
          align="center"
          label="今日发电量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="eTotal"
          align="center"
          label="累计发电量"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="pac"
          align="center"
          label="发电功率"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="hTotal"
          align="center"
          label="累计发电小时"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv1"
          align="center"
          label="直流电压 1"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv2"
          align="center"
          label="直流电压 2"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv3"
          align="center"
          label="直流电压 3"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv4"
          align="center"
          label="直流电压 4"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv5"
          align="center"
          label="直流电压 5"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv6"
          align="center"
          label="直流电压 6"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv7"
          align="center"
          label="直流电压 7"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv8"
          align="center"
          label="直流电压 8"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv9"
          align="center"
          label="直流电压 9"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv10"
          align="center"
          label="直流电压 10"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv11"
          align="center"
          label="直流电压 11"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="vpv12"
          align="center"
          label="直流电压 12"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv1"
          align="center"
          label="直流电流 1"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv2"
          align="center"
          label="直流电流 2"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv3"
          align="center"
          label="直流电流3"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv4"
          align="center"
          label="直流电流 4"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv5"
          align="center"
          label="直流电流 5"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv6"
          align="center"
          label="直流电流 6"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="ipv7"
          align="center"
          label="直流电流 7"
          width="200px">
        </el-table-column>
          <el-table-column
            prop="ipv9"
            align="center"
            label="直流电流 9"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="ipv10"
            align="center"
            label="直流电流 10"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="ipv11"
            align="center"
            label="直流电流 11"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="ipv12"
            align="center"
            label="直流电流 12"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="vac1"
            align="center"
            label="交流电压 1"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="vac2"
            align="center"
            label="交流电压 2"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="vac3"
            align="center"
            label="交流电压 3"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="iac1"
            align="center"
            label="交流电流 1"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="iac2"
            align="center"
            label="交流电流 2"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="iac3"
            align="center"
            label="交流电流 3"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="fac1"
            align="center"
            label="交流频率 1"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="fac2"
            align="center"
            label="交流频率 2"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="fac3"
            align="center"
            label="交流频率 3"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr1"
            align="center"
            label="组串电流 1"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr2"
            align="center"
            label="组串电流 2"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr3"
            align="center"
            label="组串电流 3"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr4"
            align="center"
            label="组串电流 4"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr5"
            align="center"
            label="组串电流 5"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr7"
            align="center"
            label="组串电流 6"
            width="200px">
          </el-table-column>
          <el-table-column
            prop="istr7"
            align="center"
            label="组串电流 7"
            width="200px">
          </el-table-column>
        <el-table-column
          prop="istr8"
          align="center"
          label="组串电流 8"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr9"
          align="center"
          label="组串电流 9"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr10"
          align="center"
          label="组串电流 10"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr11"
          align="center"
          label="组串电流 11"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr12"
          align="center"
          label="组串电流12"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr13"
          align="center"
          label="组串电流 13"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr14"
          align="center"
          label="组串电流 14"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr15"
          align="center"
          label="组串电流 15"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr16"
          align="center"
          label="组串电流 16"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr17"
          align="center"
          label="组串电流 17"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr18"
          align="center"
          label="组串电流 18"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr19"
          align="center"
          label="组串电流 19"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr20"
          align="center"
          label="组串电流 20"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr21"
          align="center"
          label="组串电流 21"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr22"
          align="center"
          label="组串电流 22"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr23"
          align="center"
          label="组串电流 23"
          width="200px">
        </el-table-column>
        <el-table-column
          prop="istr24"
          align="center"
          label="组串电流 24"
          width="200px">
        </el-table-column>
      </el-table>
    </div>
    <div style="line-height:10px ;margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-size="queryInfo.pagesize"
        layout="prev, pager, next"
        background
        :total=this.total>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { postHistory } from '@/api/api'

export default {
  name: 'history',
  data () {
    return {
      // 总条数
      total: 0,
      // 参数
      queryInfo: {
        query: '',
        // 当前页数
        pagenum: 1,
        // 显示一页多少条数据
        pagesize: 20
      },
      tableData: [],
      page: 1,
      date: ''
    }
  },
  mounted () {
    this.getCurrentDate()
    this.getValue()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.getValue()
    }
  },
  methods: {
    // 监听 pagesize 改变的事件
    handleSizeChange (newsize) {
      // 最新的条数（newsize）赋值给 动态的 pagesie
      this.queryInfo.pagesize = newsize
      // 获取到最新一页显示的数据  重新发送axios请求 这里是封装好的请求方法
      this.getValue()
    },

    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      // console.log('newPage', newPage)
      // 把最新的页码（newPage）赋值给 动态的 pagenum
      this.queryInfo.pagenum = newPage
      // 获取到最新显示的页码值  重新发送axios请求 这里是封装好的请求方法
      this.getValue()
    },
    getValue () {

      this.$apiFun.postHistory({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.$store.state.chooseDate,
        page: this.queryInfo.pagenum,
        rows: 20
      }).then(
        res => {
          // console.log('history', res)
          let list = []
          list = res.rows
          // console.log('list', list)
          this.tableData = list
          this.total = res.total
        }
      )
    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      this.date = year + '-' + month + '-' + day
      this.time = new Date().getTime()
      // console.log('time', this.time)
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  overflow: hidden;
  line-height: 0

}

::v-deep .has-gutter {
  line-height: 10px;
}
::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 20px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: none;
}

::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 18px;
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}

  // page-sizes选择器
::v-deep .el-select-dropdown__item li{
  color: #FFFFFF !important;
  font-size: 22px;
  background-color:transparent !important;
}
// prev和next箭头的样式
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev{
  color: #FFFFFF !important;
  background:transparent !important;
  background-color:transparent !important;
}
// prev和next箭头disabled的样式
::v-deep .el-pagination button:disabled {
  color: #FFFFFF !important;
  font-size: 18px;
  background-color:transparent !important;
}
// 页码样式
::v-deep .el-pager li{
  color: #FFFFFF !important;
  font-size: 18px;
  background-color:transparent !important;
}
// active的页码样式
::v-deep .el-pager li.active{
  font-size: 18px;
  color: #267aff !important;
}
</style>
