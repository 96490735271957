<template>
  <div class="main" style="width: 100%;height: 100%;">
    <!--<div class="navBox" style="width: 100%;height: 40px;margin-top: 5px">-->
    <div class="content" style="width: 100%;height: 100%;">
      <div style="width: 100%;height: 95%">
        <router-view></router-view>
      </div>
      <div class="bottom" style="width: 30%;padding: 0;margin-left: 30%;height: 20px">
        <div class="item">
          <router-link to="/power">
            <el-button type="primary" plain>功率</el-button>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/powerFactor">
            <el-button type="primary" plain>功率因素</el-button>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/effectiveValue">
            <el-button type="primary" plain>电量</el-button>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/harmonicWave">
            <el-button type="primary" plain>谐波畸变率</el-button>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/harmonicWaveValue">
            <el-button type="primary" plain>谐波畸变量</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'electric',
  data () {
    return {}
  },
  mounted () {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 0;

  ::v-deep .el-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  ::v-deep .el-button--primary {
    background-color: transparent;
    width: 140px;
    height: 40px;
    font-family: SimHei, serif;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }

  ::v-deep .el-button--primary:focus,
  .el-button--primary:hover {
    background: rgba(66, 120, 213, 0.3);
    border-color: rgba(66, 120, 213, 0.3);
    color: #fff;
  }

}

//选择框样式
::v-deep .el-radio-group {
  float: left;
}
</style>
