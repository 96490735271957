<template>
  <div class="main" style="margin-top: 10px; width: 100%; ">

    <div id="managerInfo" style="width: 100%; ">
      <div style="width: 100%;height: 30px;margin-top: 20px">
        <div style="width:58%;height: 30px;line-height: 30px;margin-bottom: 10px;float: left; text-align: center;">
          信息
        </div>
        <div style="width:39%;height: 30px;line-height: 30px;margin-bottom: 10px;float: right; text-align: center;">
          图例
        </div>
      </div>
      <div class="left" style="width: 58%;float: left;margin-top: 20px">
        <el-descriptions :column="1">
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              客户名称
            </template>
            {{ custName }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              客户编码
            </template>
            {{ number }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              设备总数量
            </template>
            {{ dev_total_count }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              故障设备数量
            </template>
            {{ dev_hitch_count }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              在线设备数量
            </template>
            {{ dev_online_count }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              下线设备数量
            </template>
            {{ dev_offline_count }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              联系人
            </template>
            {{ en_user }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              手机号
            </template>
            {{ phone }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              传真
            </template>
            {{ fax }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              邮编
            </template>
            {{ zip_code }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              电子邮件
            </template>
            {{ e_mail }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              居住地
            </template>
            {{ cmbCity }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              联系地址
            </template>
            {{ detailed_address }}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label1" content-class-name="my-content1">
            <template slot="label">
              备注
            </template>
            {{ remark }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div style="float: right;width:39%;height: 100%;margin-top: 30px">
        <div id="main1" style="float: right;width:100%;height: 380px ">
        </div>
        <div id="main" style="float: right;width:100%;height: 380px ">
        </div>
      </div>
    </div>
    <div id="deviceInfo" style='width: 100%; '>
      <div style="width: 100%;height: 30px;margin-top: 30px">
        <div style="width:100%;height: 30px;line-height: 30px;margin-bottom: 10px;float: left; text-align: center;">
          {{ this.dev_name }}
        </div>
      </div>
      <el-descriptions direction="horizontal" :column="2" style="margin-top: 30px">
        <el-descriptions-item label="所属产品" label-class-name="my-label" content-class-name="my-content">{{
            pro_name
          }}
        </el-descriptions-item>
        <el-descriptions-item label="设备名称" label-class-name="my-label" content-class-name="my-content">
          {{ dev_name }}
        </el-descriptions-item>
        <el-descriptions-item label="设备编号" label-class-name="my-label" content-class-name="my-content">
          {{ dev_ctw_sn }}
        </el-descriptions-item>
        <el-descriptions-item label="设备创建时间" label-class-name="my-label" content-class-name="my-content">
          {{ dev_create_time_Str }}
        </el-descriptions-item>
        <el-descriptions-item label="设备故障状态" label-class-name="my-label" content-class-name="my-content">
          {{ dev_hitch_status === 1 ? '故障' : '无故障' }}
        </el-descriptions-item>
        <el-descriptions-item label="设备注册状态" label-class-name="my-label" content-class-name="my-content">
          {{ dev_deviceStatus === 1 ? '已激活' : '已注册' }}
        </el-descriptions-item>
        <el-descriptions-item label="设备活跃状态" label-class-name="my-label" content-class-name="my-content">
          {{ dev_online_status === 1 ? '在线' : '下线' }}
        </el-descriptions-item>
        <el-descriptions-item label="设备运行状态" label-class-name="my-label" content-class-name="my-content">
          {{ dev_move_status === 1 ? '停止' : '运行' }}
        </el-descriptions-item>
        <el-descriptions-item label="设备最后上线时间" label-class-name="my-label" content-class-name="my-content">
          {{ dev_latest_update_time_Str }}
        </el-descriptions-item>
        <el-descriptions-item label="设备出厂日期" label-class-name="my-label" content-class-name="my-content">
          {{ dev_factory_time }}
        </el-descriptions-item>
        <el-descriptions-item label="设备保修周期(月)" label-class-name="my-label" content-class-name="my-content">
          {{ dev_nx }}
        </el-descriptions-item>
        <el-descriptions-item label="设备保修期" label-class-name="my-label" content-class-name="my-content">
          {{ dev_warranty_period_time_Str }}
        </el-descriptions-item>
        <el-descriptions-item label="iccid" label-class-name="my-label" content-class-name="my-content">{{ iccid }}
        </el-descriptions-item>
        <el-descriptions-item label="供应商" label-class-name="my-label" content-class-name="my-content">
          {{ ar_supplier_name }}
        </el-descriptions-item>
        <el-descriptions-item label="供应商电话" label-class-name="my-label" content-class-name="my-content">
          {{ ar_supplier_phone }}
        </el-descriptions-item>
        <el-descriptions-item label="供应商联系人" label-class-name="my-label" content-class-name="my-content">
          {{ ar_supplier_user }}
        </el-descriptions-item>
        <el-descriptions-item label="服务商" label-class-name="my-label" content-class-name="my-content">
          {{ dev_middleman_en_name }}
        </el-descriptions-item>
        <el-descriptions-item label="服务商联系人" label-class-name="my-label" content-class-name="my-content">
          {{ dev_middleman_user_name }}
        </el-descriptions-item>
        <el-descriptions-item label="服务商联系方式" label-class-name="my-label" content-class-name="my-content">
          {{ dev_middleman_user_phone }}
        </el-descriptions-item>
        <el-descriptions-item label="终端用户联系人" label-class-name="my-label" content-class-name="my-content">
          {{ dev_user_name }}
        </el-descriptions-item>
        <el-descriptions-item label="终端用户联系方式" label-class-name="my-label" content-class-name="my-content">
          {{ dev_user_phone }}
        </el-descriptions-item>
        <el-descriptions-item label="设备详细地址" label-class-name="my-label" content-class-name="my-content">
          {{ dev_address }}
        </el-descriptions-item>
        <el-descriptions-item label="设备描述" :span="4" label-class-name="my-label"
                              content-class-name="my-content"></el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'information',
  data () {
    return {
      minus:'',
      custName: '',
      number: '', // 设备编码
      dev_total_count: '',
      dev_hitch_count: '',
      dev_online_count: '',
      dev_offline_count: '',
      en_user: '',
      phone: '',
      fax: '',
      zip_code: '',
      e_mail: '',
      cmbCity: '',
      detailed_address: '',
      remark: '',
      pro_name: '',
      dev_name: '', // 名称
      dev_ctw_sn: '', // 设备编号
      ar_supplier_name: '',
      dev_online_status: '', // 设备活跃状态
      dev_deviceStatus: '',
      dev_nx: '', // 保修
      dev_move_status: '',
      iccid: '',
      dev_hitch_status: '',
      dev_create_time_Str: '', // 创建时间
      dev_latest_update_time_Str: '', // 最后上线时间
      dev_factory_time: '', // 设备出厂时间
      dev_warranty_period_time_Str: '',
      dev_middleman_en_name: '', // 服务商
      ar_supplier_phone: '', // 供应商电话
      ar_supplier_user: '', // 供应商联系人
      dev_middleman_user_name: '', // 服务商
      dev_middleman_user_phone: '',
      dev_user_name: '',
      dev_user_phone: '',
      dev_address: '' // 地址
    }
  },
  mounted () {
    // setTimeout(this.getManagerInfo(), 3000)
    // document.getElementById('deviceInfo').style.display = 'none'
    if (window.localStorage.confirmIndex == 4) {
      // console.log(window.localStorage.confirmIndex)
      document.getElementById('managerInfo').style.display = 'none'
      document.getElementById('deviceInfo').style.display = ''// 展示
      this.getDeviceInfo()
    } else {
      document.getElementById('managerInfo').style.display = ''
      document.getElementById('deviceInfo').style.display = 'none'
      this.getManagerInfo()
    }
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.view()
      if (this.$store.state.confirmIndex === 4) {
        this.getDeviceInfo()
      } else {
        this.getManagerInfo()
      }
    }
  },
  methods: {
    view () {
      if (this.$store.state.confirmIndex === 4) {
        // console.log(window.localStorage.confirmIndex)
        document.getElementById('managerInfo').style.display = 'none'
        document.getElementById('deviceInfo').style.display = ''// 展示
      } else {
        document.getElementById('managerInfo').style.display = ''
        document.getElementById('deviceInfo').style.display = 'none'
        this.$store.commit('setDevName', '')
      }
    },
    // 左侧对应管理级信息
    getManagerInfo () {
      this.$apiFun.postUserList(
        {
          userId: window.localStorage.userId,
          token: window.localStorage.token,
          code: window.localStorage.customerCode
        }
      ).then(
        res => {
          // console.log('getmanagerInfo', res)
          this.custName = res.dataInfo[0].name
          this.number = res.dataInfo[0].code
          this.dev_total_count = res.dataInfo[0].dev_total_count
          this.dev_hitch_count = res.dataInfo[0].dev_hitch_count
          this.dev_online_count = res.dataInfo[0].dev_online_count
          this.dev_offline_count = res.dataInfo[0].dev_offline_count
          this.en_user = res.dataInfo[0].en_user
          this.phone = res.dataInfo[0].number
          this.fax = res.dataInfo[0].fax
          this.zip_code = res.dataInfo[0].zip_code
          this.e_mail = res.dataInfo[0].e_mail
          this.cmbCity = res.dataInfo[0].cmbCity
          this.detailed_address = res.dataInfo[0].detailed_address
          this.remark = res.dataInfo[0].remark
          this.getEcharts()
          this.getEcharts1()
        }
      )
    },
    // 获取设备信息
    getDeviceInfo () {
      this.$apiFun.postGetDeviceInfo({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId
      }).then(
        res => {
          // console.log('deviceInfo', res)
          this.pro_name = res.dataInfo.pro_name
          this.dev_name = res.dataInfo.dev_name // 设备名称
          this.$store.commit('setDevName', res.dataInfo.dev_name)
          // console.log('dev_name', this.$store.state.dev_name)
          this.dev_ctw_sn = res.dataInfo.dev_ctw_sn
          this.ar_supplier_name = res.dataInfo.ar_supplier_name
          this.dev_online_status = res.dataInfo.dev_online_status
          this.dev_deviceStatus = res.dataInfo.dev_deviceStatus
          this.dev_nx = res.dataInfo.dev_nx
          this.dev_move_status = res.dataInfo.dev_move_status
          this.iccid = res.dataInfo.iccid
          this.dev_hitch_status = res.dataInfo.dev_hitch_status
          this.dev_create_time_Str = res.dataInfo.dev_create_time_Str
          this.dev_latest_update_time_Str = res.dataInfo.dev_latest_update_time_Str
          this.dev_factory_time = res.dataInfo.dev_factory_time
          this.dev_warranty_period_time_Str = res.dataInfo.dev_warranty_period_time_Str
          this.dev_middleman_en_name = res.dataInfo.dev_middleman_en_name
          this.ar_supplier_phone = res.dataInfo.ar_supplier_phone
          this.ar_supplier_user = res.dataInfo.ar_supplier_user
          this.dev_middleman_user_name = res.dataInfo.dev_middleman_user_name
          this.dev_middleman_user_phone = res.dataInfo.dev_middleman_user_phone
          this.dev_user_name = res.dataInfo.dev_user_name
          this.dev_user_phone = res.dataInfo.dev_user_phone
          this.dev_address = res.dataInfo.dev_address
        }
      )
    },
    getEcharts () {
      const chartDom = document.getElementById('main')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
          trigger: 'item'
        },

        legend: {
          orient: 'vertical',
          x: 'left',
          y: 'top',
          textStyle: { //图例文字的样式
            color: '#fff',
            fontSize: 18
          }
        },
        series: [
          {
            name: '信息',
            type: 'pie',
            radius: '50%',
            data: [
              {
                value: this.dev_offline_count,
                name: '离线设备',
                itemStyle: {
                  normal: {
                    color: '#1566d3'
                  }

                }
              },
              {
                value: this.dev_online_count,
                name: '在线设备',
                itemStyle: {
                  normal: {
                    color: '#2e89f5'
                  }

                }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    getEcharts1 () {
      const chartDom = document.getElementById('main1')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
          trigger: 'item'
        },

        legend: {
          orient: 'vertical',
          x: 'left',
          y: 'top',
          textStyle: { //图例文字的样式
            color: '#fff',
            fontSize: 18
          }
        },
        series: [
          {
            name: '信息',
            type: 'pie',
            radius: '50%',
            data: [
              {
                value: this.dev_hitch_count,
                name: '故障设备',
                itemStyle: {
                  normal: {
                    color: '#7bc8ea'
                  }

                }
              },
              {
                value: this.dev_total_count-this.dev_hitch_count,
                name: '正常设备',
                itemStyle: {
                  normal: {
                    color: '#34baec'
                  }

                }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-descriptions__body {
  background-color: transparent !important;
  font-size: 20px;
}

::v-deep .el-descriptions__header {
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 40px;
}

::v-deep .el-descriptions-row {
  background: transparent !important;
}

::v-deep .my-label {
  padding-left: 30px;
  background: rgba(66, 120, 213, 0.3) !important;
  display: flex;
  color: #FFFFFF;
  width: 250px;
  line-height: 48px;
  height: 48px;
}

::v-deep .my-content {
  padding-left: 30px;
  background: rgba(66, 120, 213, 0.3) !important;
  width: 380px;
  line-height: 48px;
  color: #FFFFFF;
  height: 48px;
}

::v-deep .my-label1 {
  padding-left: 30px;
  background: rgba(66, 120, 213, 0.3) !important;
  width: 320px;
  color: #FFFFFF;
  line-height: 42px;
  height: 42px;
}

::v-deep .my-content1 {
  padding-left: 30px;
  background: rgba(66, 120, 213, 0.3) !important;
  width: 630px;
  line-height: 42px;
  height: 42px;
  color: #FFFFFF;
}
</style>
