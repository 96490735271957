<template>
  <div class="main" style="width: 100%;height: 100%">
    <div class="top">
      <el-table
        :data="tableData" style="height: 290px"
        max-height="290px">
        <el-table-column
          prop="timestamp"
          align="center"
          label="时间"
          width="210px">
        </el-table-column>
        <el-table-column
          prop="data_p_u_a"
          align="center"
          label="A相有功功率（kW）"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="data_p_u_b"
          align="center"
          label="B相有功功率（kW）"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="data_p_u_c"
          align="center"
          label="C相有功功率（kW）"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="data_q_i_a"
          align="center"
          label="A相无功功率（kVar）"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="data_q_i_b"
          align="center"
          label="B相无功功率（kVar）"
          width="230px">
        </el-table-column>
        <el-table-column
          prop="data_q_i_c"
          align="center"
          label="C相无功功率（kVar）"
          width="230px">
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom" style="height:500px;margin-top: 18px">
      <div id="echartPa_arr" style="height: 500px;width: 800px;float: left"></div>
      <div id="echartQa_arr" style="height: 500px;width: 800px;float: right"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { postPowerValue } from '@/api/api'

export default {
  name: 'effectiveValue',
  data () {
    return {
      date: this.$store.state.chooseDate,
      time: '',
      tableData: [],
      pa_arr: {},
      qa_arr: {}
    }
  },
  mounted () {
    this.getCurrentDate()
    this.getValue()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.state.chooseDate
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      this.getValue()
    }
  },
  methods: {
    getValue () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postPowerValue({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.date
      }).then(
        res => {
          // console.log('value', res)
          let list = []
          list = res.statisticsList.pa_list
          // console.log('list', list)
          this.tableData = list
          this.pa_arr = res.statisticsTable.pa_arr
          this.qa_arr = res.statisticsTable.qa_arr
          this.getEchartPa_arr()
          this.getEchartQa_arr()
        }
      )
    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      // this.date = year + '-' + month + '-' + day
      // this.time = new Date().getTime()
      // console.log('time', this.time)
    },
    getEchartPa_arr () {
      let dataList = []
      let labels = []
      dataList = this.pa_arr.dataList
      labels = this.pa_arr.labels
      // console.log('labels!!!', this.pa_arr)
      const chartDom = document.getElementById('echartPa_arr')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '有功功率（kW）', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000', // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相有功功率（kW）',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相有功功率（kW）',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相有功功率（kW）',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartQa_arr () {
      let dataList = []
      let labels = []
      dataList = this.qa_arr.dataList
      labels = this.qa_arr.labels
      // console.log('labels!!!', this.qa_arr)
      const chartDom = document.getElementById('echartQa_arr')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '无功功率（kVar）', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000', // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid',
              }
          }
        },
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相无功功率（kVar）',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相无功功率（kVar）',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相无功功率（kVar）',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .has-gutter {
  line-height: 10px;
}

::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 20px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 20px;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}
</style>
