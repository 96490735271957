<template>
  <div id="app">
    <scale-box :width="1920" :height="1020" bgc="transparent" :delay="100">
      <nav>
      <router-link to="/login"></router-link>
      </nav>

    <router-view/>
    </scale-box>
  </div>
</template>


<script>
import ScaleBox from 'vue2-scale-box';
export default {
  components: { ScaleBox }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*background: url("./assets/loginBg.jpg");*/
  /*background-size: 100% 100%;*/
  /*height: 100%;*/
  /*width: 100%;*/
  /*position: fixed;*/
  width: 100vw;
  height: 100vh;
  background-color: #033c76;
  /*background-color: #032e5b;*/
  overflow: hidden;
  color: white;
}
body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}
</style>
