<template>
  <div class="main">
    <div class="top" style="width: 100%; height: 66%; ">
      <div class="left leftState" style="width: 23%; height: 100%; margin-left:18px ">
        <!--        登录账户为三级账户-->
        <div class="user" v-show="true" style="height: 100%">
          <div class="box leftState" style="height: 45.5%">
            <!--            <div class="title"><i class="el-icon-warning-outline" style="color: #c70303"></i> 告警</div>-->
            <div class="content boxall">
              <div class="alltitle">设备状态</div>
              <div class="wrap boxnav">
                <ul>
                  <li><p>
                    <i class="dotClass"
                       style="background-color: springgreen;width:12px;  height:12px;  border-radius: 50%;  display: block"></i><span>{{
                      this.devInfo1.name
                    }}</span><span>{{ this.devInfo1.num }}</span>
                  </p></li>
                  <li><p>
                    <i class="dotClass"
                       style="background-color: #6b6a6a ;width:12px;  height:12px;  border-radius: 50%;  display: block"></i><span>{{
                      this.devInfo2.name
                    }}</span><span>{{ this.devInfo2.num }}</span>
                  </p></li>
                  <li><p>
                    <i class="dotClass"
                       style="background-color: red ;width:12px;  height:12px;  border-radius: 50%;  display: block"></i><span>{{
                      this.devInfo3.name
                    }}</span><span>{{ this.devInfo3.num }}</span>
                  </p></li>
                  <li><p>
                    <i class="dotClass"
                       style="background-color: #ff7300 ;width:12px;  height:12px;  border-radius: 50%;  display: block"></i><span>{{
                      this.devInfo4.name
                    }}</span><span>{{ this.devInfo4.num }}</span>
                  </p></li>

                </ul>
              </div>
            </div>
          </div>
          <div class="box leftState" style="height: 51%">
            <div class="content">
              <div class="" style="height:280px;width: 400px;margin-left: 20px" id="ecahrt3"></div>
            </div>
          </div>
        </div>
      </div>
      <!--中间部分-->
      <div class="  center" style=" width: 50%;height: 100%;">
        <div class=" box leftState" style="height: 100%; width: 100%; margin-left: 18px;">
          <div class="mapnav">
            <ul>
              <li>
                <div>
                  <label id="name1"></label>
                  <p>{{ mapvalue[0] }}</p></div>
              </li>
              <li>
                <div>
                  <label id="name2"></label>
                  <p>{{ mapvalue[1] }}</p></div>
              </li>
              <li>
                <div>
                  <label id="name3"></label>
                  <p>{{ mapvalue[2] }}</p></div>
              </li>
              <li>
                <div>
                  <label id="name4"></label>
                  <p>{{ mapvalue[3] }}</p></div>
              </li>
              <li>
                <div>
                  <label id="name5"></label>
                  <p>{{ mapvalue[4] }}</p></div>
              </li>
            </ul>
          </div>

          <div class="map" id="map" ref="echarts" style="margin-top: 75px;">
          </div>
        </div>
      </div>

      <div class="right leftState" style="width: 23%; height: 100%;margin-left: 36px">
        <!--        登录账户为一二级账户-->
        <div class="admin" id="company">
          <div class="box leftState tableoOut" ref="tableoOut" style="height:100%;width: 100%">
            <!-- 参数名称、列数根据实际情况调整 -->
            <div class="table_body">
              <div class="table_th">
                <div class="tr1 th_style">名称</div>
                <div class="tr2 th_style">地址</div>
                <div class="tr3 th_style">设备数</div>
              </div>
              <div class="table_main_body">
                <div class="table_inner_body" :style="{top: tableTop + 'px'}">
                  <div class="table_tr" v-for="(item,index) in tableList" :key="index">
                    <div class="tr1 tr">{{ item.name }}</div>
                    <div class="tr2 tr">{{ item.address }}</div>
                    <div class="tr3 tr">{{ item.dev_total }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        登录账户为三级-->
        <div class="user" id="dev" style="height: 100%">
          <div class="box leftState tableoOut" ref="tableoOut" style="height:100%;width: 100%">
            <!-- 参数名称、列数根据实际情况调整 -->
            <div class="table_body">
              <div class="table_th">
                <div class="tr1 th_style">设备名称</div>
                <div class="tr2 th_style">设备地址</div>
                <div class="tr3 th_style">设备类型</div>
              </div>
              <div class="table_main_body">
                <div class="table_inner_body" :style="{top: tableTop + 'px'}">
                  <div class="table_tr" v-for="(item,index) in tableList" :key="index">
                    <div class="tr1 tr">{{ item.name }}</div>
                    <div class="tr2 tr">{{ item.address }}</div>
                    <div class="tr3 tr">{{ item.type }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部两部分折线图-->
    <div class="bottom " style="width: 97.9%;height: 31%;margin-left: 18px; margin-top: 10px">
      <div class="box leftState" style="width: 31%; height: 100%;float: left;">
        <div id="company" style="width: 100%;height: 100%">
          <div class="ftechart" id="echart1" style="height: 80%;width: 92%;margin-left: 20px;margin-top: 5%"></div>
        </div>
        <div id="dev" style="width: 100%;height: 100%">
          <div class="ftechart" id="echart1" style="height: 80%;width: 92%;margin-left: 20px;margin-top: 5%"></div>
        </div>
      </div>
      <div class="box leftState" style="width: 31%; height: 100%; float: left;margin-left: 16px;">
        <div id="company" style="width: 100%;height: 100%">
          <div class="ftechart" id="echart2" style="height: 80%;width: 92%;margin-left: 20px;margin-top: 5%"></div>
        </div>
        <div id="dev" style="width: 100%;height: 100%">
          <div class="ftechart" id="echart2" style="height: 80%;width: 92%;margin-left: 20px;margin-top: 5%"></div>
        </div>
      </div>
      <div class="box leftState" style="width: 36%; height: 100%; float: left;margin-left: 15px">
        <div class="boxall">
          <div class="alltitle" style=""><i class="el-icon-warning-outline" style="color: #c70303;font-size: 26px;"></i>
            设备告警列表
          </div>
        </div>
        <!--            :cell-style="{'text-align':'center'}"-->
        <div style="width: 93.5%; height: 90%;margin-top: 5px;margin-left: 20px">
          <el-table
            :data="tableData"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            max-height="230px"
            @row-click="getAlarmMessage">
            <el-table-column
              prop="ar_create_time"
              label="时间"
              width="170">
            </el-table-column>
            <el-table-column
              prop="ar_user_en_name"
              label=" 用户名称"
              width="250">
            </el-table-column>
            <el-table-column
              prop="dev_name"
              label="设备"
              width="200">
            </el-table-column>
          </el-table>
          <el-dialog title="告警详情" :visible.sync="show" width="45%" :modal-append-to-body="false">
            <el-descriptions :column="1">
              <el-descriptions-item label="产品名称">{{ alarmMessage.pro_name }}</el-descriptions-item>
              <el-descriptions-item label="设备名称">{{ alarmMessage.dev_name }}</el-descriptions-item>
              <el-descriptions-item label="设备sn">{{ alarmMessage.dev_ctw_sn }}</el-descriptions-item>
              <el-descriptions-item label="告警名称">{{ alarmMessage.ar_name }}</el-descriptions-item>
              <el-descriptions-item label="告警内容">{{ alarmMessage.ar_content }}</el-descriptions-item>
              <el-descriptions-item label="告警级别">{{ alarmMessage.ar_level }}</el-descriptions-item>
              <el-descriptions-item label="告警时间">{{ alarmMessage.ar_create_time }}</el-descriptions-item>
              <el-descriptions-item label="用户联系人">{{ alarmMessage.ar_user_name }}</el-descriptions-item>
              <el-descriptions-item label="用户联系人电话">{{ alarmMessage.ar_user_phone }}</el-descriptions-item>
            </el-descriptions>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import '../../node_modules/echarts/map/js/china'
import loadJs from '@/utils/message'
import login from '../LoginView'
// 引入地图
import 'echarts/lib/chart/map'
// 引入js
import 'echarts/map/js/china.js'
import axios from 'axios'
import { postHomeView } from '@/api/api'
import mqtt from 'mqtt'
import { v4 } from 'uuid'

const echarts = require('echarts') // 引入中国地图数据
export default {
  name: 'homeView',
  data: function () {
    return {
      logs: [],
      msg: 0,
      uuid: 8888,
      tableTimer: null,
      column: '',
      tableTop: 0,
      mapvalue: [],
      postionData: {}, // 地图地址
      devInfo1: '',
      devInfo2: '',
      devInfo3: '',
      devInfo4: '',
      allData: [],
      tableData: [],
      circulateList: [],
      foldLineList: [], // 趋势折线
      tableList: [],
      tableListSize: 0,
      visibleSize: 9, // 容器内可视最大完整行数
      lineHeight: 50, // 每行的实际高度（包含margin-top/bottom,border等）
      tableTimerInterval: 100, // 向上滚动 1px 所需要的时间，越小越快，推荐值 100
      alarmMessage: '',
      show: false
    }
  },
  //  A 页面（组件）
  computed: {
    status () {
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    // logs (newVal, oldValue) {
    //   // 监听组件props的list，发生改变时就会调用它
    //   console.log('watchmsg', newVal, oldValue)
    // },
    status () {
      this.getValue()
    }
  },
  mounted () {
    const msg1 = this.msg
    this.uuid = v4()
    // console.log('this.uuid', this.uuid)
    // console.log('mouted', this.allData)
    this.initMqtt()
    this.mqttReceive()
    this.getValue()
    this.view()
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'remote-js': {
      render (createElement) {
        return createElement('script', {
          attrs: {
            type: 'text/javascript',
            src: this.src
          }
        })
      },
      props: {
        src: {
          type: String,
          required: true
        }
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.componentTimer);
    clearInterval(this.tableTimer)
  },
  destroyed () {
    if (this.client.end) this.client.end()
  },
  methods: {
    /**
     * @name:初始化mqtt
     * @msg:
     * @param {*}
     * @return {*}
     */
    initMqtt () {
      const vm = this
      // const commonApi = 'http://113.141.171.81:1883'
      const mqtt = require('mqtt')
      // console.log('this.uuid', this.uuid)
      const options = {
        // mqtt客户端的id
        clientId: this.uuid,
        username: 'admin',
        password: '123456',
        // useSSL: true,
        rejectUnauthorized: false,
        clean: true, // 保留会话
        cleanSession: true,
        connectTimeout: 4000, // 超时时间
        reconnectPeriod: 4000, // 重连时间间隔
        keepalive: 60
      }
      vm.client = mqtt.connect('wss://api.jsttpower.cn:9002', options)
      // vm.client = mqtt.connect('ws://127.0.0.1:9001', options)
      this.client.on('connect', function () {
        console.log('连接成功....')
      })
      // 如果连接错误，打印错误
      vm.client.on('error', function (err) {
        console.log('err=>', err)
        vm.client.end()
      })
      // 重连回调
      vm.client.on('reconnect', (error) => {
        console.log('正在重连MQTT服务器:', error)
      })
    },
    /**
     * @name:发布mqtt消息
     * @msg:
     * @param {*}
     * @return {*}
     */
    mqttPublish () {
      const topic = 'topic_ttwlMsg' + '/' + window.localStorage.code + '_WEB'
      this.client.publish(topic, JSON.stringify(this.message))
    },
    /**
     * @name:接收mqtt消息
     * @msg:
     * @param {*}
     * @return {*}
     */
    mqttReceive () {
      // console.log('topicrecive', window.localStorage.code)
      const topic = 'topic_ttwlMsg' + '/' + window.localStorage.code + '_WEB'// 和后台约定好的主题
      // console.log('topic', topic)
      const vm = this
      vm.client.subscribe(topic, function (err) {
        if (!err) {
          console.log('订阅成功')
        } else {
          // 打印错误
          console.log('err', err)
        }
      })
      vm.client.on('message', function (topic, message) {
        // console.log(message.toString())
        vm.logs.push(message)
        const status = vm.$store.state.chooseDevice// 重新赋值
        vm.$store.commit('changeDevice', !status)
        console.log('vm.$store.state.chooseDevice', vm.$store.state.chooseDevice)
      })
    },
    canParseToJson (str, parseFlag = true) {
      try {
        if (typeof JSON.parse(str) === 'object' && Object.prototype.toString.call(JSON.parse(str)) === '[object Object]') {
          return parseFlag === true ? JSON.parse(str) : true
        }
      } catch (e) {
      }
      return false
    },
    getValue () {
      // console.log('调用getvalue')
      this.$apiFun.postHomeView({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code
      }).then(
        res => {
          // this.msg ++
          // console.log('value', res)
          // this.$nextTick(
          //   function () {
          const data = res.dataInfo
          const list = res.dataInfo.arlist
          this.allData = data
          this.tableData = list
          this.setValue()
          this.checkIndex()
          this.position()
          this.echarts_1()
          this.echarts_2()
          this.echarts_3()
          setTimeout(this.cellStyle, 40000)
          this.tableActionFun()
          this.map()
        }
      )
      //   }
      // )
    },
    // 告警信息详情
    getAlarmMessage (row, event, column) {
      // console.log(row)
      this.$apiFun.postAlarmMessage({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        ar_id: row.ar_id
      }).then(
        res => {
          // console.log(res)
          this.alarmMessage = res.alarmRecord
          this.show = true // 控制弹窗显示
        }
      )
    },
    view () {
      if (window.localStorage.managerIndex == 3) {
        // console.log('view', this.$store.state.confirmIndex)
        document.getElementById('company').style.display = 'none'
        document.getElementById('dev').style.display = 'ture'// 展示
      } else {
        document.getElementById('company').style.display = 'ture'
        document.getElementById('dev').style.display = 'none'
      }
    },
    // check 管理级别
    checkIndex () {
      // console.log('window.localStorage.confirmIndex ', window.localStorage.managerIndex)
      if (window.localStorage.managerIndex == 3 ) {
        this.tableList = this.allData.devList
        this.foldLineList = this.allData.devTable
        // console.log('this.foldLineList ', this.tableList)
        // this.echarts_1_1()
      } else {
        this.tableList = this.allData.enList
        this.foldLineList = this.allData.enTable
        // console.log('this.tableList', this.tableList)
      }
    },
    setValue () {
      const div = document.getElementById('name1')
      div.innerText = this.allData.smTable.name[0]
      const div1 = document.getElementById('name2')
      div1.innerText = this.allData.smTable.name[1]
      const div2 = document.getElementById('name3')
      div2.innerText = this.allData.smTable.name[2]
      const div3 = document.getElementById('name4')
      div3.innerText = this.allData.smTable.name[3]
      const div4 = document.getElementById('name5')
      div4.innerText = this.allData.smTable.name[4]
      this.mapvalue = this.allData.smTable.number
      this.devInfo1 = this.allData.devStatisticsList[0]
      this.devInfo2 = this.allData.devStatisticsList[1]
      this.devInfo3 = this.allData.devStatisticsList[2]
      this.devInfo4 = this.allData.devStatisticsList[3]
    },
    // 根据列表长度是否超过可视范围内能够显示的最大完整数据条数，来控制列表是否需要滚动
    tableActionFun () {
      // console.log('this.tableList.length', this.tableList.length)
      this.tableListSize = this.tableList.length
      // 下面的visibleSize是可视范围内能够显示的最大完整数据条数
      if (this.tableListSize > this.visibleSize) {
        this.tableList = this.tableList.concat(this.tableList)
        this.tableTimerFun() // 列表滚动方法
      } else {
        this.fillTableList() // 无需滚动时的数据填充方法，如果没必要，可以删掉
      }
    },
    // 列表滚动方法
    tableTimerFun () {
      console.log('发生轮播')
      let count = 0
      if (this.tableTop > -450) {
        this.tableTimer
      } else {
        clearInterval(this.tableTimer)
      }
      this.tableTimer = setInterval(() => {
        if (count < (this.tableList.length / 2) * this.lineHeight) {
          this.tableTop -= 1.5
          count++
        } else {
          count = 0
          this.tableTop = 0
        }
      }, 200)
      // console.log('tableTimerInterval',this.tableTimerInterval)
    },
    // 当数据过少时，不触发自动轮播事件，并填充没有数据的行，参数根据实际情况修改即可
    fillTableList () {
      // console.log('不发生轮播')
      const addLength = this.visibleSize - this.tableListSize
      if (window.localStorage.confirmIndex == 3) {
        for (let i = 0; i < addLength; i++) {
          this.tableList.push({
            name: '-',
            address: '-',
            type: '-'
          })
        }
      } else {
        for (let i = 0; i < addLength; i++) {
          this.tableList.push({
            company: '-',
            number: '-',
            adress: '-',
            state: '-'
          })
        }
      }
    },
    // 设置table颜色
    cellStyle (column) {
      // console.log('column', column.column) 设置table某列颜色
      // if (column.column.property === 'ar_content') {
      //   return {
      //     color: 'red'
      //   }
      // }
    },
    echarts_1 () {
      let echartName = ''
      if (window.localStorage.confirmIndex == 3) {
        echartName = '区域设备统计'
      } else {
        echartName = '区域用户统计'
      }
      const myChart = echarts.init(document.getElementById('echart1'))
      let option
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        legend: {
          x: 'center',
          y: '10',
          icon: 'circle',
          itemGap: 8,
          textStyle: { color: 'rgba(255,255,255,1)' },
          itemWidth: 10,
          itemHeight: 10
        },
        grid: {
          left: '0',
          top: '40',
          right: '15',
          bottom: '0',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.allData.enCityTable.labels,
          axisLine: { show: false },

          axisLabel: {
            // interval: 0 , //设置间隔为0
            rotate: 30,
            textStyle: {
              color: 'rgba(255,255,255,.5)'

            }
          }
        },

        yAxis: {
          type: 'value',
          splitNumber: 4,
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.05)'
            }
          },
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,.5)'
            }
          }
        },
        series: [
          // {
          //   name: '用电',
          //   type: 'bar',
          //   barWidth: '25%',
          //   itemStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#8bd46e'
          //       }, {
          //         offset: 1,
          //         color: '#03b48e'
          //       }]),
          //       barBorderRadius: 11
          //     }
          //
          //   },
          //   data: [123, 154, 234, 321, 120, 390]
          //
          // },
          {
            name: echartName,
            type: 'bar',
            barWidth: '20%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#3893e5'
                }, {
                  offset: 1,
                  color: '#248ff7'
                }]),
                barBorderRadius: 3
              }
            },
            data: this.allData.enCityTable.dataList

          }
          // {
          //   name: '设备数',
          //   type: 'bar',
          //   barWidth: '25%',
          //   itemStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#676ae5'
          //       }, {
          //         offset: 1,
          //         color: '#3a3d75'
          //       }]),
          //       barBorderRadius: 11
          //     }
          //   },
          //   data: [63, 194, 234, 321, 278, 110]
          //
          // }

        ]
      }
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    echarts_1_1 () {
      const myChart = echarts.init(document.getElementById('echart1_1'))
      let option
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        legend: {
          x: 'center',
          y: '10',
          icon: 'circle',
          itemGap: 8,
          textStyle: { color: 'rgba(255,255,255,1)' },
          itemWidth: 10,
          itemHeight: 10
        },
        grid: {
          left: '0',
          top: '40',
          right: '15',
          bottom: '0',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.allData.enCityTable.labels,
          axisLine: { show: false },

          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,.5)'

            }
          }
        },

        yAxis: {
          type: 'value',
          splitNumber: 4,
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.05)'
            }
          },
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,.5)'
            }
          }
        },
        series: [
          // {
          //   name: '用电',
          //   type: 'bar',
          //   barWidth: '25%',
          //   itemStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#8bd46e'
          //       }, {
          //         offset: 1,
          //         color: '#03b48e'
          //       }]),
          //       barBorderRadius: 11
          //     }
          //
          //   },
          //   data: [123, 154, 234, 321, 120, 390]
          //
          // },
          {
            name: '区域用户统计',
            type: 'bar',
            barWidth: '20%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#3893e5'
                }, {
                  offset: 1,
                  color: '#248ff7'
                }]),
                barBorderRadius: 3
              }
            },
            data: this.allData.enCityTable.dataList

          }
          // {
          //   name: '设备数',
          //   type: 'bar',
          //   barWidth: '25%',
          //   itemStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#676ae5'
          //       }, {
          //         offset: 1,
          //         color: '#3a3d75'
          //       }]),
          //       barBorderRadius: 11
          //     }
          //   },
          //   data: [63, 194, 234, 321, 278, 110]
          //
          // }

        ]
      }
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 增长趋势图
    echarts_2 () {
      const myChart = echarts.init(document.getElementById('echart2'))
      let option
      // eslint-disable-next-line prefer-const
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: '20%',
          right: '50',
          bottom: '20',
          left: '30'
        },
        legend: {
          // data: ['字段4', '字段5', '字段6'],
          right: 'center',
          width: '100%',
          textStyle: {
            color: '#fff'
          },
          itemWidth: 12,
          itemHeight: 10
        },

        xAxis: [
          {
            type: 'category',
            data: this.foldLineList.labels,
            axisLine: { lineStyle: { color: 'rgba(255,255,255,.1)' } },
            axisLabel: {
              textStyle: {
                color: 'rgba(255,255,255,.7)',
                fontSize: '14'
              }
            }

          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '单位个',
            axisTick: { show: false },
            splitLine: {
              show: false

            },
            axisLabel: {
              show: true,
              fontSize: 14,
              color: 'rgba(255,255,255,.6)'

            },
            axisLine: {
              min: 0,
              max: 10,
              lineStyle: { color: 'rgba(255,255,255,.1)' }
            }// 左线色

          }
          // {
          //   type: 'value',
          //   name: '增速',
          //   show: true,
          //   axisLabel: {
          //     show: true,
          //     fontSize: 14,
          //     formatter: '{value} %',
          //     color: 'rgba(255,255,255,.6)'
          //   },
          //   axisTick: { show: false },
          //   axisLine: { lineStyle: { color: 'rgba(255,255,255,.1)' } }, // 右线色
          //   splitLine: {
          //     show: true,
          //     lineStyle: { color: 'rgba(255,255,255,.1)' }
          //   }// x轴线
          // }
        ],
        series: [

          // {
          //   name: '字段1',
          //   type: 'bar',
          //   data: [36.6, 38.80, 40.84, 41.60],
          //   barWidth: '15%',
          //   itemStyle: {
          //     normal: {
          //       barBorderRadius: 15,
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //         offset: 0,
          //         color: '#8bd46e'
          //       }, {
          //         offset: 1,
          //         color: '#09bcb7'
          //       }])
          //     }
          //   },
          //   barGap: '0.2'
          // },
          {
            name: '用户增长趋势',
            type: 'line',
            smooth: true,
            yAxisIndex: 0,
            data: this.foldLineList.dataList,
            lineStyle: {
              normal: {
                width: 4
              }
            },
            itemStyle: {
              normal: {
                color: '#86d370'

              }
            }

          }
          // {
          //   name: '字段5',
          //   type: 'line',
          //   yAxisIndex: 0,
          //
          //   data: [0, -4.73, 6.38, 8.67],
          //   lineStyle: {
          //     normal: {
          //       width: 2
          //     }
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#3496f8'
          //
          //     }
          //   },
          //   smooth: true
          // },
          // {
          //   name: '字段6',
          //   type: 'line',
          //   yAxisIndex: 1,
          //
          //   data: [0, -1.09, 8.24, -9.64],
          //   lineStyle: {
          //     normal: {
          //       width: 2
          //     }
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#fbc30d'
          //
          //     }
          //   },
          //   smooth: true
          // }
        ]
      }

      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 告警饼图
    echarts_3 () {
      // console.log('hello')
      const echarts3Data = []

      if (this.allData.arTable.length > 1) {
        this.allData.arTable.forEach(function (item, index) {
          echarts3Data.push({
            name: item.name,
            value: item.quantity
          })
        })
      } else if (this.allData.arTable.length == 1) {
        echarts3Data.push({
          name: this.allData.arTable[0].name,
          value: this.allData.arTable[0].quantity
        })
      } else {
        echarts3Data.push({
          name: '',
          value: ''
        })
      }
      // console.log(echarts3Data)
      // 基于准备好的dom，初始化echarts实例
      const chartDom = document.getElementById('ecahrt3')
      const myChart = echarts.init(chartDom)
      let option

      option = {
        title: {
          text: '设备告警统计',
          subtext: '',
          left: 10,
          top: 20,
          textStyle: {
            color: 'white' // 标签文字颜色改为白色
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: ['#86d370', '#3496f8', '#f8c034'],
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 18,
            color: '#fff'
          },
          orient: 'vertical',
          x: 'right', // 可设定图例在左、右、居中
          y: 'top', // 可设定图例在上、下、居中
          padding: [25, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          // top: '1%',
          // left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            center: ['45%', '60%'],
            radius: ['30%', '55%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderWidth: 2
            },
            label: {
              // show: false,
              position: 'outer'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            labelLine: {
              show: false
            },
            data: echarts3Data
          }
        ]
      }

      option && myChart.setOption(option)

      // window.addEventListener('resize', function () {
      //   myChart.resize()
      // })
    },
    position () {
      this.allData.areaLatList.map(itm => {
        this.postionData[itm.cmbCity] = itm.longLatArr
      })
      // console.log('arr1', this.postionData)
    },
    map () {
      const myChart = echarts.init(this.$refs.echarts)
      // const chinaGeoCoordMap = {
      //   黑龙江: [127.9688, 45.368],
      //   // 内蒙古: [110.3467, 41.4899],
      //   // 吉林: [125.8154, 44.2584],
      //   // 宜宾市: [104.630825, 28.760189],
      //   // 辽宁: [123.1238, 42.1216],
      //   // 河北: [114.4995, 38.1006],
      //   天津: [117.4219, 39.4189],
      //   // 山西: [112.3352, 37.9413],
      //   // 陕西: [109.1162, 34.2004],
      //   甘肃: [103.5901, 36.3043],
      //   // 宁夏: [106.3586, 38.1775],
      //   // 青海: [101.4038, 36.8207],
      //   // 新疆: [87.9236, 43.5883],
      //   西藏: [91.11, 29.97],
      //   // 四川: [103.9526, 30.7617],
      //   // 重庆: [108.384366, 30.439702],
      //   // 山东: [117.1582, 36.8701],
      //   // 河南: [113.4668, 34.6234],
      //   // 江苏: [118.8062, 31.9208],
      //   // 安徽: [117.29, 32.0581],
      //   // 湖北: [114.3896, 30.6628],
      //   浙江: [119.5313, 29.8773],
      //   // 福建: [119.4543, 25.9222],
      //   // 江西: [116.0046, 28.6633],
      //   // 湖南: [113.0823, 28.2568],
      //   // 贵州: [106.6992, 26.7682],
      //   // 云南: [102.9199, 25.4663],
      //   // 广东: [113.12244, 23.009505],
      //   // 广西: [108.479, 23.1152],
      //   // 海南: [110.3893, 19.8516],
      //   上海: [121.4648, 31.2891]
      // }
      const chinaGeoCoordMap = this.postionData
      // console.log('chinaGeoCoordMap ', chinaGeoCoordMap)
      // 散点
      const chinaDatas = [{
        name: '江苏',
        value: [118.8062, 31.9208]
      }]
      const mapObject = {
        name: '',
        value: null
      }
      // 飞线
      const lineObject = { coords: [[118.8062, 31.9208]] }
      const linesCoord = []
      for (const key in chinaGeoCoordMap) {
        mapObject.name = key
        mapObject.value = chinaGeoCoordMap[key]
        chinaDatas.push(JSON.parse(JSON.stringify(mapObject)))
        if (key !== '江苏') {
          lineObject.coords[1] = chinaGeoCoordMap[key]
          linesCoord.push(JSON.parse(JSON.stringify(lineObject)))
        }
      }
      const option = {
        // geo配置详解： https://echarts.baidu.com/option.html#geo
        geo: {
          map: 'china',
          show: true,
          roam: true,
          top: '20px',
          label: {
            emphasis: {
              show: false
            }
          },
          // 地图的背景色
          itemStyle: {
            normal: {
              areaColor: '#338FCC',
              borderColor: '#1376d0',
              shadowColor: '#338FCC'
            }
          }
        },
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            // 要有对应的经纬度才显示，先经度再维度
            data: chinaDatas,
            showEffectOn: 'render',
            rippleEffect: {
              scale: 4, // 波纹的最大缩放比例
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              normal: {
                show: true,
                formatter: '{b}',
                position: 'right',
                fontWeight: 500,
                fontSize: 14
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            emphasis: {
              itemStyle: {
                color: '#fff' // 高亮颜色
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            coordinateSystem: 'geo',
            zlevel: 1,
            effect: {
              show: true,
              period: 5,
              trailLength: 0,
              symbol: 'arrow',
              color: '#fff',
              symbolSize: 15
            },
            lineStyle: {
              normal: {
                width: 1.2,
                opacity: 0.6,
                curveness: 0.2,
                color: '#FFf'
              }
            },
            data: linesCoord
          }
        ]
      }
      myChart.setOption(option)
      // window.addEventListener('resize', function() {
      //   myChart.resize()
      // })
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;

  ::v-deep .has-gutter {
    line-height: 15px;
  }

  ::v-deep .el-table {
    background-color: #033c76 !important; /* 背景透明 */
  }

  ::v-deep .el-table th {
    color: #ffffff; /* 字体颜色 */
    font-size: 18px;
    font-weight: 400;
    background-color: #033c76 !important; /* 背景透明 */
    border: 0;
  }

  ::v-deep .el-table tr, .el-table td {
    font-size: 18px;
    background-color: #033c76 !important; /* 背景透明 */
    border: 0;
    color: red;
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #ecc815 !important;
    color: red;
  }

  ::v-deep .el-dialog {
    /* background: rgba(0, 0, 0, .2) !important; */
    background: rgba(0, 0, 0, 0.2) !important;
    border: rgb(95, 156, 243) 1px solid;
    backdrop-filter: blur(3px);
  }

  ::v-deep .el-dialog__title {
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 22px;
  }
  // 弹出框样式
  ::v-deep .el-dialog__header {
    color: #fff;
    background: rgba(0, 0, 0, 0.5) !important;
    text-align: center;
    box-shadow: 0 0 1.5vw rgb(95, 156, 243) inset;
    background: linear-gradient(#5f9cf3, #5f9cf3) left top,
    linear-gradient(#5f9cf3, #5f9cf3) left top,
    linear-gradient(#5f9cf3, #5f9cf3) right top,
    linear-gradient(#5f9cf3, #5f9cf3) right top;
    /* linear-gradient(#3deeda, #3deeda) left bottom,
    linear-gradient(#3deeda, #3deeda) left bottom,
    linear-gradient(#3deeda, #3deeda) right bottom,
    linear-gradient(#3deeda, #3deeda) right bottom; */
    background-repeat: no-repeat;
    background-size: 2px 20px, 20px 2px;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5) !important;
    text-align: left;
    box-shadow: 0 0 1.5vw rgb(95, 156, 243) inset;
    background: linear-gradient(#5f9cf3, #5f9cf3) left bottom,
    linear-gradient(#5f9cf3, #5f9cf3) left bottom,
    linear-gradient(#5f9cf3, #5f9cf3) right bottom,
    linear-gradient(#5f9cf3, #5f9cf3) right bottom;
    background-repeat: no-repeat;
    background-size: 2px 20px, 20px 2px;
  }

  ::v-deep .el-descriptions__body {
    padding-left: 10px;
    background-color: rgba(95, 156, 243, .6) !important;
    font-size: 20px;
    color: #FFFFFF;
  }

  .leftState {
    float: left;
  }

  .box {
    width: 100%;
    height: 48%;
    border: 1px solid rgba(7, 118, 181, .7);
    box-shadow: inset 0 0 5px rgba(7, 118, 181, .8);
    margin-bottom: 4.2%;
    position: relative;
  }

  .title {
    width: 94.5%;
    height: 8%;
    padding: 10px 10px 10px 25px;
    border-bottom: 1px solid rgba(7, 118, 181, .7);
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    //background: linear-gradient(to right, #037cc7, #0496e1, #037cc7);
    box-shadow: 0 0 2px rgb(137, 194, 232);
  }

  .title:after, .box:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
    box-shadow: 0 0 8px rgba(131, 189, 227, 1);
  }

  .table1 th {
    border-bottom: 1px dotted #407abd;
    font-size: 22px;
    text-align: center;
    padding: 10px 13px;
    color: rgba(255, 255, 255, .8)
  }

  .table1 td {
    border-bottom: 1px dotted #407abd;
    font-size: 20px;
    padding: 10px 0;
    text-align: center;
    color: rgba(255, 255, 255, .6)
  }

  .table1 tr:last-child td {
    border: none;
  }

  //轮播信息

  .table_body {
    width: 100%;
    margin-top: 12px;
  }

  .table_th {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    height: 40px;
    line-height: 40px;
  }

  .tr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;
  }

  .tr1 {
    width: 42%;
    margin-left: 5px;
  }

  .tr2 {
    width: 30%;
  }

  .tr3 {
    width: 29%;
  }

  .tr4 {
    flex: 1;
  }

  .th_style {
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 5px;
    text-align: center;
  }

  .table_main_body {
    width: 100%;
    height: 550px;
    overflow: hidden;
    position: relative;
  }

  .table_inner_body {
    width: 100%;
    position: absolute;
    left: 0;
  }

  .table_tr {
    display: flex;
    height: 50px;
    line-height: 50px;
    color: #eee;
    font-size: 18px;
    background: rgba(3, 92, 155, 0.1);
    border: 1px solid rgb(91, 135, 211);
    margin-top: 7px;
  }

  //地图
  .mapc {
    background: url(../../assets/bg3.png) no-repeat center center;
    background-size: 100% 100%
  }

  .map {
    position: relative;
    height: 100%
  }

  .map img {
  }

  .mapnav {
    position: absolute;
    z-index: 100;
  }

  .mapnav div {
    background: url(../../assets/bg1.png) no-repeat;
    background-size: 110% auto;
    width: 140px;
    text-align: center;
    padding: 25px 0;
    line-height: 120%;
  }

  .mapnav div span {

    font-size: 16px;
    opacity: .6
  }

  .mapnav div p {
    font-size: 20px;
    margin-top: 11px;
    font-weight: bold;
  }

  .mapnav li {
    list-style: none;
    float: left;
    margin-right: 6px;
  }

  .sycm li {
    list-style: none;
    text-align: center;
    padding: 2.5px 2px;
    position: relative;
    float: left;
    width: 45%;
  }

  .sycm ul li:nth-child(odd):before {
    position: absolute;
    content: "";
    height: 20%;
    width: 2px;
    background: rgba(255, 255, 255, .2);
    right: 0;
    top: 30%;
  }

  .sycm li h2 {
    font-size: 23px;
    margin-top: 6px;
    color: #fff;
  }

  .sycm li span {
    font-size: 16px;
    color: #fff;
    opacity: .7;
  }

  .yqlist li {
    list-style: none;
    float: left;
    width: 50%;
    padding: 4px 0;
    text-align: center;
  }

  .yq {
    width: 90px;
    height: 90px;
    margin: 0 auto 4px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    font-family: electronicFont;
    font-weight: 500;
    color: #fff;
  }

  .yqlist li span {
    opacity: .6;
    font-size: 16px;
  }

  .yq:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: url(../../assets/img1.png) center center;
    border-radius: 100px;
    background-size: 100% 100%;
    opacity: .3;
    left: 0;
    top: 0;
    animation: myfirst2 15s infinite linear;
  }

  .yq:after {
    position: absolute;
    width: 86%;
    background: url(../../assets/img2.png) center center;
    border-radius: 100px;
    background-size: 100% 100%;
    opacity: .3;
    height: 86%;
    content: "";
    left: 7%;
    top: 7%;
    animation: myfirst 15s infinite linear;
  }

  .ftechart {
    height: 200px;;
  }

  .leidanav {
    margin-top: -40px;
    margin-left: 8px;
    width: 100%
  }

  .leidanav li {
    list-style: none;
    float: left;
    width: 18%;
    text-align: center;
  }

  .leidanav span {
    font-size: 12px;
    opacity: .6;
    margin-right: 10px;
    text-align: center;
    height: 20px;
    border-left: 2px solid rgba(255, 255, 255, .1)
  }

  .leidanav p {
    height: 20px;
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
  }

  .leidanav span:first-child {
    border-left: none
  }

  .top {
    text-align: center;
    position: relative;

    .left, .center, .bottom {
      float: left;
    }
  }

  //.boxall{ border: 1px solid rgba(25,186,139,.17);   background: rgba(255,255,255,.04) ; background-size: 100% auto; position: relative; margin-bottom: .15rem; z-index: 10;}
  .boxall:before,
  .boxall:after {
    position: absolute;
    width: 5px;
    height: 5px;
    content: "";
    border-top: 2px solid #02a6b5;
    top: 0;
  }

  .boxall:before, .boxfoot:before {
    border-left: 2px solid #02a6b5;
    left: 0;
  }

  .boxall:after, .boxfoot:after {
    border-right: 2px solid #02a6b5;
    right: 0;
  }

  .alltitle {
    font-size: 20px;
    color: #ffffff;
    line-height: 50px;
    border-bottom: 1px rgba(255, 255, 255, .2);
    background-color: rgba(6, 97, 197, 0.9)
  }

  //.boxnav{height: calc(100% - .4rem);}
  .wrap {
    overflow: hidden;
  }

  .wrap li {
    line-height: 30px;
    height: 30px;
    color: #FFFFFF
  }

  .wrap li p {
    border: 1px solid rgba(25, 186, 139, .17);
    width: 100%;
    margin-left: -20px
  }

  .wrap li p {
    display: flex;
    justify-content: space-between;
  }

  .wrap li p {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .wrap li p span {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 40%;
    font-size: 20px;
  }

  .wrap li p span:first-child {
    width: 40%;
    text-align: left;
  }
}
</style>
