<template>
  <div class="main" style="height: 100%;">
    <div style="height: 100%">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        background-color="#407abd"
        text-color="#fff"
        active-text-color="#ffd04b">
        <el-input
          placeholder="输入关键字查找"
          prefix-icon="el-icon-search"
          v-model="input"
        @change="searchList()">
        </el-input>
        <el-tree class="filter-tree" :data="userList" :props="defaultProps" @node-click="handleNodeClick" ref="treeData" node-key="id" default-expand-all   highlight-current ></el-tree>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'asideList',
  data () {
    return {
      input: '',
      userList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {
    input (val) {
    }
  },
  mounted () {
    this.getUserList()
  },
  methods: {
    // 搜索
    // filterNode (value, data) {
    //   if (!value) return true
    //   console.log('data', data.label)
    //   return data.label.indexOf(value) !== -1
    // },
    handleNodeClick (data) {
      // console.log(data)
      if (data.id) {
        this.$refs.treeData.setCurrentKey(data.id)
      }
      // this.$store.commit('setDeviceId',)
      this.$store.commit('setCustomerCode', data.code)
      this.$store.commit('setConfirmIndex', data.en_index)
      this.$store.commit('setDev_type', data.dev_type)
      const status = this.$store.state.chooseDevice// 重新赋值
      this.$store.commit('changeDevice', !status)
    },
    // 获取当前客户子集
    getUserList () {
      this.$apiFun.postUserList(
        {
          userId: window.localStorage.userId,
          token: window.localStorage.token,
          code: window.localStorage.code
        }
      ).then(
        res => {
          const list = res.dataInfo
          console.log('res.dataInfo', res.dataInfo)
          const arr1 = []
          list.forEach(function (item, index) {
            // console.log('index',index)
            arr1.push({
              label: item.name,
              code: item.code,
              en_index: item.en_index,
              dev_type: item.dev_type
            })
            if (item.children) {
              const arr2 = []
              item.children.forEach(function (item1, index1) {
                // console.log('index1',index1)
                arr2.push({
                  label: item1.name,
                  code: item1.code,
                  en_index: item1.en_index,
                  dev_type: item1.dev_type
                })
                // sanji
                if (item1.children) {
                  const arr3 = []
                  item1.children.forEach(function (item2, index2) {
                    // console.log('index2',index2)
                    arr3.push({
                      label: item2.name,
                      code: item2.code,
                      en_index: item2.en_index,
                      dev_type: item2.dev_type
                    })
                    // console.log('arr3',arr3)
                    if (item2.children) {
                      const arr4 = []
                      item2.children.forEach(function (item3) {
                        arr4.push({
                          label: item3.name,
                          code: item3.code,
                          en_index: item3.en_index,
                          dev_type: item3.dev_type
                        })
                        arr3[index2].children = arr4
                      })
                    }
                  })
                  arr2[index1].children = arr3
                }
              })
              arr1[index].children = arr2
            }
          })
          this.userList = arr1
        }
      ).catch()
    },
    searchList () {
      // console.log('input',this.input)
      this.$apiFun.postUserList(
        {
          userId: window.localStorage.userId,
          token: window.localStorage.token,
          code: window.localStorage.code,
          queryValue: this.input
        }
      ).then(
        res => {
          const list = res.dataInfo
          // console.log('list1', list)
          const arr1 = []
          list.forEach(function (item, index) {
            arr1.push({
              label: item.name,
              code: item.code,
              en_index: item.en_index
            })
            if (item.children) {
              const arr2 = []
              item.children.forEach(function (item1, index1) {
                arr2.push({
                  label: item1.name,
                  code: item1.code,
                  en_index: item1.en_index
                })
                // sanji
                if (item1.children) {
                  const arr3 = []
                  item1.children.forEach(function (item2) {
                    arr3.push({
                      label: item2.name,
                      code: item2.code,
                      en_index: item2.en_index
                    })
                  })
                  arr2[index1].children = arr3
                }
              })
              arr1[index].children = arr2
            }
          })
          this.userList = arr1
        }
      ).catch()
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  .el-submenu ::v-deep .el-submenu__title {
    font-size: 20px;
    font-weight: 600;
    height: 70px;
    line-height: 70px;
  }

  .el-submenu ::v-deep .el-menu-item {
    margin-left: 40px;
  }

  /*最外层背景色。 字体颜色*/
  .el-tree {
    background: #033c76;
    color: #fff;

  }
  ::v-deep.el-tree .el-tree-node__label {
    font-size: 18px;
    font-weight: normal;
  }
  /*当鼠标点击tree的节点时显示的背景色,   字体颜色 */
  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background-color: #033c76 !important;
    color: #fff;
  }

  ::v-deep .el-tree-node__content {
    font-size: 22px;
    font-weight: 500;
    line-height: 70px;
    height: 70px;
  }

  ::v-deep .el-tree-node__content {
    &:hover {
      background: #104e96;
    }
  }

  ::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #104e96 !important;

    color: #dadada;

  }
}
</style>
