<template>
  <div class="Login" style="width: 100%; height: 100%">
    <div class="back" style="width: 100%; height: 100%">
      <section>
        <form >
          <p style="font-size: 22px">5G设备监控管理云平台登陆</p>
          <input type="text" placeholder="企业号" ref="companyNumber">
          <input type="text" placeholder="用户名" ref="userName">
          <input type="password" placeholder="请输入密码" ref="password">
            <button   @click.prevent="submitForm('form')"  @click="login()">登陆</button>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line camelcase
import router from '@/router'
import mqtt from 'mqtt'
import { v4 } from 'uuid'
import axios from 'axios'
const utilMd5 = require('../utils/md5')

export default {
  name: 'LoginView',
  data () {
    return {
      uuid: '',
      client: '',
      message: '',
      logs: [],
      date: '',
      companyNumber: '',
      userName: '',
      password: '',
      MD5_KEY: 'ttwl_2023'
    }
  },
  mounted () {
    if (localStorage.getItem(Login)) {
      this.bgImg = window.URL.createObjectURL(new Blob([res.data]))
    } else {
      this.bgImg = require('../assets/background.jpg')
      axios.get(document.querySelector('#Login').src, {
        responseType: 'arraybuffer'
      })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('Login', res.data) // 这块保存有问题 自己研究吧
            console.log(new Blob([res.data]))
          }
        })
    }
  },
  beforeUpdate () {
  },
  methods: {
    submitForm () {

    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      this.date = year + '-' + month + '-' + day
      this.time = new Date().getTime()
      // console.log('time', this.time)
      this.$store.commit('setChooseDate', this.date)
    },
    login () {
      // console.log('this.$refs.companyNumber.value', this.$refs.companyNumber.value)
      this.companyNumber = this.$refs.companyNumber.value
      this.userName = this.$refs.userName.value
      this.password = this.$refs.password.value
      // this.companyNumber = 'ttwl'
      // this.userName = 'admin'
      // this.password = '888888'
      // console.log(this.companyNumber, this.userName, this.password)
      const sms = utilMd5.hex_md5(this.MD5_KEY)
      const token = utilMd5.hex_md5(this.companyNumber + this.userName + this.password + sms)
      this.$apiFun.postLogin({
        code: this.companyNumber,
        account: this.userName,
        password: this.password,
        token: token
      }).then(
        res => {
          // console.log('res', res)
          const h = this.$createElement
          if (res.msgInfo == 0) {
            this.$notify({
              message: h('i', { style: 'color: red;background-color: blue' }, '请求不合法'),
              type: 'warning',
              offset: 100
            })
          } else if (res.msgInfo == 1){
            // this.$notify({
            //   message: h('i', { style: 'color: #5f9cf3' }, '登陆成功'),
            //   type: 'success',
            //   offset: 100,
            //   customClass: "focus-message"
            // })
            this.$store.commit('setToken', token)
            this.$store.commit('setUserInfo', res.enterprise.name)
            this.$store.commit('setUserId', res.sysUser.user_id)
            this.$store.commit('setCode', this.companyNumber)
            this.$store.commit('setConfirmIndex', res.enterprise.en_index)
            this.$store.commit('setManagerIndex', res.enterprise.en_index)
            // console.log(this.$store.state.confirmIndex)
            setTimeout(router.push('/nav'), 3000)
            this.$store.commit('setDateTotal', res.dateTotal)
          } else if (res.msgInfo == 2){
            this.$message.error('账号或密码错误')
          } else if (res.msgInfo == 3) {
            this.$message.warning('企业号不存在')
          } else if (res.msgInfo == 4) {
            this.$message.error('账号已停用')
          } else {
            this.$message.error('企业号已停用')
          }
        })
    }
  }

}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  overflow-x: hidden;
  .Login {
    width: 100%;
    min-height: 100vh;
    background: url("../assets/background.jpg") center center no-repeat;
    background-size: 100% 100%;
  }
}
 ::v-deep .focus-message {
   background-color: #407abd;
   color: #FFFFFF;
 }
section {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-image: linear-gradient(94.3deg, rgb(3, 60, 118) 10.9%, rgba(3, 60, 118, 0) 87.1%);
  overflow: hidden;
}

form {
  position: relative;
  //z-index: 3;
  width: 400px;
  background-color: rgb(3, 60, 118);
  padding: 45px 30px;
  border-radius: 5px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .1);
  border: 1px solid rgba(255, 255, 255, .1);
  border-right: 1px solid rgba(255, 255, 255, .1);
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  backdrop-filter: blur(20px);
}

form p {
  color: #fff;
  display: block;
  text-align: center;
  margin: 0 0 30px 0;
}

input {
  width: 380px;
  height: 50px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, .5);
  margin-bottom: 15px;
  padding-left: 15px;
  color: #fff;
  outline: none;
}

input::placeholder {
  color: #fff;
}

button {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background-image: linear-gradient(to right, #1971ee 0%, #1971ee 51%, #1566d3 100%);
  background-size: 200% auto;
  border-radius: 8px;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .1);
  transition: ease 0.4s;
}

button:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
</style>
