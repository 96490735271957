<template>
<div>
  <div style="height:805px">
    <el-table
      :data="tableData" style="height:805px"
      :cell-style="cellStyle"
      max-height="805">
      <el-table-column
        prop="pro_name"
        align="center"
        label="产品名称"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="dev_name"
        align="center"
        label="设备名称"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="dev_ctw_sn"
        align="center"
        label="设备sn"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="ar_name"
        align="center"
        label="告警名称"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="ar_content"
        align="center"
        label="告警内容"
        width="400px">
      </el-table-column>
      <el-table-column
        prop="ar_level"
        align="center"
        label="告警级别"
        width="100px">
      </el-table-column>
      <el-table-column
        prop="ar_status"
        align="center"
        label="告警状态"
        width="100px">
      </el-table-column>
      <el-table-column
        prop="ar_create_time"
        align="center"
        label="告警时间"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="ar_update_time"
        align="center"
        label="处理时间"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="ar_user_name"
        align="center"
        label="用户联系人"
        width="150px">
      </el-table-column>
      <el-table-column
        prop="ar_user_phone"
        align="center"
        label="用户联系人电话"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="ar_processing_username"
        align="center"
        label="处理人"
        width="150px">
      </el-table-column>
      <el-table-column
        prop="ar_processing_content"
        align="center"
        label="处理内容"
        width="350px">
      </el-table-column>
      <el-table-column
        prop="ar_processing_status"
        align="center"
        label="处理状态"
        width="150px">
      </el-table-column>
      <el-table-column
        prop="dev_middleman_en_name"
        align="center"
        label="服务商"
        width="250px">
      </el-table-column>
      <el-table-column
        prop="dev_middleman_user_name"
        align="center"
        label="服务商联系人"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="dev_middleman_user_phone"
        align="center"
        label="服务商名称"
        width="300px">
      </el-table-column>
      <el-table-column
        prop="ar_supplier_name"
        align="center"
        label="供应商名称"
        width="300px">
      </el-table-column>
      <el-table-column
        prop="ar_supplier_user"
        align="center"
        label="供应商联系人"
        width="150px">
      </el-table-column>
      <el-table-column
        prop="ar_supplier_phone"
        align="center"
        label="供应商电话"
        width="200px">
      </el-table-column>
    </el-table>
  </div>
  <div style="line-height:10px ;margin-top: 10px">
    <el-pagination
      @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="queryInfo.pagenum" :page-size="queryInfo.pagesize"
      layout="prev, pager, next"
      background
      :total=this.total>
    </el-pagination>
  </div>
</div>
</template>

<script>
import { postWarnMessage } from '@/api/api'

export default {
  name: 'warn',
  data () {
    return {
      // 总条数
      total: 0,
      // 参数
      queryInfo: {
        query: '',
        // 当前页数
        pagenum: 1,
        // 显示一页多少条数据
        pagesize: 20
      },
      tableData: [],
      page: 1,
      date: this.$store.state.chooseDate
    }
  },
  mounted () {
    this.getValue()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      // return this.$store.state.chooseDate
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
      // eslint-disable-next-line no-unreachable
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      console.log('this.date', this.date)
      this.getValue()
    }
  },
  methods: {
    getValue () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postWarnMessage({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.date,
        page: this.queryInfo.pagenum,
        rows: 20
      }).then(
        res => {
          // console.log('value', res)
          let list = []
          list = res.rows
          // console.log('list', list)
          this.tableData = list
          this.total = res.total
          this.cellStyle()
        }
      )
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newsize) {
      // 最新的条数（newsize）赋值给 动态的 pagesie
      this.queryInfo.pagesize = newsize
      // 获取到最新一页显示的数据  重新发送axios请求 这里是封装好的请求方法
      this.getValue()
    },
    // 设置table颜色
    cellStyle (column) {
      // console.log('column', column.column)
      if (column.column.property === 'ar_content') {
        return {
          color: 'red'
        }
      }
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      // console.log('newPage', newPage)
      // 把最新的页码（newPage）赋值给 动态的 pagenum
      this.queryInfo.pagenum = newPage
      // 获取到最新显示的页码值  重新发送axios请求 这里是封装好的请求方法
      this.getValue()
    }
  }
}
</script>

<style scoped>
::v-deep .has-gutter {
  line-height: 10px;
}
::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 20px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: none;
}

::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 17px;
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}
/*// page-sizes选择器*/
   ::v-deep .el-select-dropdown__item li{
     color: #FFFFFF !important;
     font-size: 22px;
     background-color:transparent !important;
   }
/*// prev和next箭头的样式*/
   ::v-deep .el-pagination .btn-next,
   ::v-deep .el-pagination .btn-prev{
     color: #FFFFFF !important;
     background:transparent !important;
     background-color:transparent !important;
   }
/*// prev和next箭头disabled的样式*/
   ::v-deep .el-pagination button:disabled {
     color: #FFFFFF !important;
     font-size: 18px;
     background-color:transparent !important;
   }
/*// 页码样式*/
   ::v-deep .el-pager li{
     color: #FFFFFF !important;
     font-size: 18px;
     background-color:transparent !important;
   }
/*// active的页码样式*/
   ::v-deep .el-pager li.active{
     font-size: 18px;
     color: #267aff !important;
   }
</style>
